/*
 *
 * JSNS
 * Author: Martin Winkler @ PORTA
 * url: http://www.portadesign.cz/
 *
 */

/************************************************************************************
RESET
*************************************************************************************/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, figure, footer, header, hgroup, menu, nav, section, menu,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

ol, ul { list-style: none; }

blockquote, q { quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after { content: ''; content: none; }

:focus { outline: 0; }

table { border-collapse: collapse; border-spacing: 0; }

article, aside, figure, footer, header, hgroup, nav, section, img { display: block; }

input[type="radio"] { vertical-align: text-bottom; }
input[type="checkbox"] { vertical-align: bottom; *vertical-align: baseline; }

label, input[type=button], input[type=submit], button { cursor: pointer; overflow: visible; }

em, i { font-weight: inherit; }

/************************************************************************************
VARS AND MIXINS
*************************************************************************************/

$sectionMargin: 0 0 36px 0;

/* Mixins */

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}

@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin scale($scale) {
    -webkit-transform: scale($scale);
    -moz-transform: scale($scale);
    -ms-transform: scale($scale);
    -o-transform: scale($scale);
    transform: scale($scale);
}

@mixin translate($translateX,$translateY) {
    -webkit-transform: translate($translateX,$translateY);
    -moz-transform: translate($translateX,$translateY);
    -ms-transform: translate($translateX,$translateY);
    -o-transform: translate($translateX,$translateY);
    transform: translate($translateX,$translateY);
}

/************************************************************************************
GENERAL
*************************************************************************************/

html { height: 100%; }

body {
    font-family: 'Merriweather',sans-serif;
    font-size: 14px;
    line-height: 1.625em;
    color: #252525;
    background: #fff;
    margin: 0;
    padding: 0;
    min-height: 100%;
}

/* Box sizing */

*, *:after, *:before { box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }

/* Anchors */

a { color: #054a7e; text-decoration: underline; }
a:hover { text-decoration: none; }

/* Heading */

h1, .h1 { font: 600 26px/1.5em 'Merriweather',sans-serif; color: #e2001a; margin: 0 0 27px 0; }
h2, .h2 { font: 600 23px/1.3em 'Merriweather',sans-serif; color: #e2001a; margin: 0 0 9px 0; }
h3, .h3 { font: 600 19px/1.3em 'Merriweather',sans-serif; color: #e2001a; margin: 0 0 9px 0; }
h4, .h4 { font: 600 16px/1.3em 'Merriweather',sans-serif; color: #e2001a; margin: 0 0 9px 0; }
h5, .h5 { font: 600 15px/1.3em 'Merriweather',sans-serif; color: #e2001a; margin: 0 0 9px 0; }
h6, .h6 { font: 600 13px/1.3em 'Merriweather',sans-serif; color: #e2001a; margin: 0 0 9px 0; }

h1.alt, .h1.alt { color: #e2001a; }

/* Paragraphs */

p { margin: 0 0 9px 0; }
address { font-style: normal; }
cite { font-style: normal; }

/* Lists */

ul, ol { list-style: none;  margin: 0 0 18px 0; }
ul li { padding: 0 0 0 18px; margin: 0 0 5px 0; }
ul.unstyled li { padding: 0; background: none; }

ol { counter-reset: counter; margin-left: 0; *margin-left: 49px; }
ol li { margin: 0 0 9px 0; padding: 0 0 0 23px; *list-style-type: decimal; counter-increment: counter; position: relative; }
ol li:before { color: #2285c8; content: counter(counter)'. '; position: absolute; left: 0; top: 0; }

dl { margin: 0 0 18px 0; }
dt { font-weight: bold; }

/* Forms */

form { display: block; }
fieldset { border: none; }
label { font-weight: bold; }
input, select, button { vertical-align: top; }

/* Various */

sup,sub { height: 0; line-height: 1; vertical-align: baseline; _vertical-align: bottom; position: relative; }
sup { bottom: 1ex; }
sub { top: .5ex; }

small { font-size: 80%; color: #252525; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

p.small { font-size: 70%; }
p.big { font-size: 130%; }

hr.divider { border-top: 2px solid #d5dce4; margin: 0; padding: 0; }

img { max-width: 100%; height: auto; display: inline-block; vertical-align: top; }
img.alignleft { float: left; margin-right: 18px; margin-bottom: 18px; }
img.alignright { float: right; margin-left: 18px; margin-bottom: 18px; }

.bold, b, strong { font-weight: 600; }
.italic, i, em { font-style: italic; }
.nowrap, .nobr { white-space: nowrap !important; }

abbr[title] { cursor: help; }

.hide, .hidden {
  display: none;
}

/* Alignment */

.v-top { vertical-align: top; }
.v-middle { vertical-align: middle; }
.v-bottom { vertical-align: bottom; }

.l { float: left; }
.r { float: right; }
.a-l { text-align: left; }
.a-r { text-align: right; }
.a-c { text-align: center; }

/* Embed container */
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

/************************************************************************************
HEADER
*************************************************************************************/

.header { padding: 0; background: #fff; height: 95px; }
.header .logo { display: block; position: absolute; left: 14px; top: 17px; z-index: 20; max-width: 225px; margin: 0; }

/* Main navigation */

.main-nav { margin: 0; position: absolute; right: 27px; top: 54px; }
.main-nav ul { margin: 0 0 0 -27px; }
.main-nav ul li { font-size: 16px; font-weight: 600; padding: 0 0 0 27px; background: none; display: inline-block; vertical-align: top; }
.main-nav ul li a { text-decoration: none; }
.main-nav ul li a:hover { color: #e41028; text-decoration: underline; }
.main-nav ul li.active a { color: #e41028; }
.main-nav ul li:last-child a { border: 2px solid #1193d8; padding: 10px 14px; margin: -10px -14px -10px 0; text-wrap: nowrap; }

/* Service navigation */

.service-nav { position: absolute; right: 30px; top: 14px; }
.service-nav ul { margin: 0 0 0 -27px; }
.service-nav ul li { display: inline-block; vertical-align: top; padding-left: 27px; }
.service-nav ul li a { font-size: 16px; color: rgba(5,74,126,0.7); }
.service-nav ul li a:hover { color: #e41028; }
.service-nav ul li a:hover .icon { color: #e41028; }
.service-nav ul li .logout { text-decoration: none; width: 14px; height: 14px; display: inline-block; vertical-align: top; }
.service-nav ul li .logout .icon { width: 14px; height: 14px; position: relative; top: -5px; }
.service-nav .icon { width: 22px; height: 22px; color: #054a7e; margin-right: 9px; }

.service-nav ul li .logged-in a, .service-nav ul li .logged-in .icon { color: #e41028 !important; }

/* Lng */

.lng img { display: inline-block; vertical-align: top; margin-right: 9px; position: relative; top: 2px; }

/* Search */

.search {  }

/* Mobile nav */

.nav-btn { padding: 9px; display: none; vertical-align: top; margin-top: 27px; float: right; position: relative; z-index: 50; }
.nav-btn span { width: 27px; height: 5px; border-bottom: 12px double #e2001a; border-top: 4px solid #e2001a; content:""; display: block; text-decoration: none; box-sizing: content-box; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; }

.mobile-nav { background: #054a7e; height: 100%; position: fixed; top: 0; right: 0; z-index: 2000; overflow-y: scroll; width: 100%; max-width: 288px; padding: 54px 0 0 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: translate(100%,0);
    -moz-transform: translate(100%,0);
    -ms-transform: translate(100%,0);
    -o-transform: translate(100%,0);
    transform: translate(100%,0);
}
body.mobile-nav-opened { overflow: hidden; }
.mobile-nav.active {
    -webkit-transform: translate(0,0);
    -moz-transform: translate(0,0);
    -ms-transform: translate(0,0);
    -o-transform: translate(0,0);
    transform: translate(0,0);
}
.mobile-nav .close { font-size: 27px; width: 14px; height: 14px; position: absolute; right: 27px; top: 12px; color: #fff; text-decoration: none; }
.mobile-nav .close .icon { width: 14px; height: 14px; }
.mobile-nav .close:hover { color: #fff; }
.mobile-nav ul { margin: 0; padding: 0; border-bottom: 1px solid rgba(255,255,255,0.2); }
.mobile-nav ul li { font-size: 16px; color: #fff; margin: 0; padding: 0; background: none; border: none; border-top: 1px solid rgba(255,255,255,0.2); }
.mobile-nav ul li.active a { background: rgba(0,0,0,0.2); }
.mobile-nav ul li.last { border: none; }
.mobile-nav ul li a { font-size: 14px; font-weight: 400; display: block; width: 100%; padding: 14px 18px; color: #fff; border: none; position: relative; text-decoration: none; }
.mobile-nav ul li span.more { position: absolute; right: 0; top: 0; width: 42px; height: 100%; background: #f6f6f6 url('../img/sprite.png') -104px -32px no-repeat; }
.mobile-nav ul li .logout .icon { position: static; left: 0; top: 0; }

.mobile-nav ul ul { display: none; border-top: none; border-bottom: none; }
.mobile-nav ul ul.active { display: block; }
.mobile-nav ul ul li a { background: #f6f6f6; padding-left: 45px; }

.mobile-nav ul ul ul li a { padding-left: 63px; }

.mobile-nav .service-nav-mobile ul { border: none; }
.mobile-nav .service-nav-mobile ul li { position: relative; padding-left: 27px; }
.mobile-nav .service-nav-mobile ul li .icon { position: absolute; left: -14px; top: 16px; }
.mobile-nav .service-nav-mobile ul li .logout { position: absolute; right: 18px; top: 50%; width: 27px; height: 27px; line-height: 27px; margin-top: -14px; text-align: center; padding: 0; }
.mobile-nav .service-nav-mobile ul li .logout .icon { position: static; left: 0; top: 0; vertical-align: middle; }
.mobile-nav .lng { position: absolute; left: 14px; top: 18px; }

.mobile-nav-overlay { position: fixed; left: 0; top: 0; z-index: 100; width: 100%; height: 100%; background: rgba(0,0,0,0.9); display: none; }

/************************************************************************************
CONTENT
*************************************************************************************/

body{display:flex; flex-direction: column;height: 100%}
body>*{flex:none;}
body>.content{flex: 1 0 auto;}
.container { width: 100%; max-width: 1260px; }
.container.homepage { width: 100%; max-width: 1728px; }
.container.full { max-width: 100%; }
.content { padding: 0;}

/* Block */

.block { padding: 63px 0; position: relative; }
.block.narrow { padding: 36px 0; }

.block.alt { background: #ededed; }
.block.alt-2 { background: #d5dce4; }

.block.alpha { padding-top: 0; }
.block.omega { padding-bottom: 0; }

.anchor { position: absolute; left: 0; top: 0; }

/* Layout */
.layout {
  .layout-sidebar {
	float: left;
	clear: left;
	width: 324px;
  }
  .layout-content {
	float: right;
	clear: right;
	width: calc(100% - 387px);
  }
  @media (max-width: 1079px) {
	.layout-sidebar {
	  width: 270px;
	}
	.layout-content {
	  width: calc(100% - 315px);
	}
  }
  @media (max-width: 691px) {
	.layout-sidebar, .layout-content {
	  float: none;
	  width: auto;
	}
  }
  &::after {
	content: "";
	display: block;
	clear: both;
  }
}

/* Various */

.section-head { text-align: center; margin-bottom: 18px; }
.section-head h1 { margin-bottom: 0; }
.section-head h2 { margin-bottom: 0; }
.section-head h3 { margin-bottom: 0; }
.section-head-l { margin-bottom: 18px; position: relative; }
.section-head-l .right { position: absolute; right: 0; top: 5px; margin-bottom: 27px; }

.intro { max-width: 450px; margin: 0 auto 36px auto; text-align: center; }
.intro.big { font-size: 15px; line-height: 1.5em; }
.intro.bottom { font-size: 13px; line-height: 1.625em; margin-bottom: 0; }
.intro.intro-1000 { max-width: 900px; }
.intro.intro-max { max-width: none; }

.narrow-col-940 { max-width: 940px; margin-left: auto; margin-right: auto; }
.narrow-col-1000 { max-width: 900px; margin-left: auto; margin-right: auto; }
.narrow-col-900 { max-width: 810px; margin-left: auto; margin-right: auto; }
.narrow-col-700 { max-width: 630px; margin-left: auto; margin-right: auto; }
.narrow-col-500 { max-width: 450px; margin-left: auto; margin-right: auto; }

.box { background: #fff; padding: 27px; margin-bottom: $sectionMargin; }

/* Teaser */

.teaser { margin: 0 auto; height: 450px; width: 100%; max-width: 1728px; background: #275f8c; position: relative; }
.teaser-content { height: 450px; }
.teaser-content .btn:last-child { margin-bottom: 0; }

.teaser-img { height: 450px; padding-right: 378px; position: relative; }
.teaser-img h1, .teaser-img h2 { color: #fff; margin-bottom: 63px; }
.teaser-img h1 b, .teaser-img h2 b { color: #8ed8ff; }
.teaser-img h1 strong, .teaser-img h2 strong { color: #8ed8ff; }
.teaser-img .head { padding-left: 126px; padding-right: 126px; position: relative; top: 50%; max-width: 1008px; height: auto; @include translate(0,-50%); }

.teaser-img .btn { font-size: 18px; background: #e2001a; color: #fff; margin-right: 14px; padding: 21px 27px 18px 27px; }
.teaser-img .btn:hover { background: #fff; border-color: #fff; color: #e2001a; }
.teaser-img .btn.alt { border: 2px solid #8ed8ff; color: #fff; background: transparent; }
.teaser-img .btn.alt:hover { background: #fff; color: #054a7e; border-color: #fff; }
.teaser-img .btn-video { margin-left: 148px; }
.teaser-img .btn-video .img { position: absolute; left: -147px; top: 50%; width: 147px; overflow: hidden; border-radius: 4px; -moz-border-radius: 4px; -webkit-border-radius: 4px; box-shadow: rgba(0,0,0,0.5) 1px 1px 4px 0; @include translate(0,-50%); }
.teaser-img .btn-wrap { position: relative; display: inline-block; vertical-align: top; }
.teaser-img .btn-wrap .title { font-size: 14px; font-weight: 400; color: #fff; position: absolute; left: 164px; top: 100%; padding-top: 14px; padding-left: 14px; }

.teaser-nav { position: absolute; right: 0; top: 0; width: 378px; height: 100%; background: #1193d8; }
.teaser-nav .item { padding: 27px; width: 100%; height: 150px; border-bottom: 1px solid #0c76b4; text-decoration: none; position: relative; }
.teaser-nav .item:hover, .teaser-nav .item.hl:hover { background: white; border-color: #0c76b4; border-top: 1px solid #0c76b4; }
.teaser-nav .item:hover h3, .teaser-nav .item.hl:hover h3 { color: #e2001a; }
.teaser-nav .item:not(:first-child):hover { margin-top: -1px; }
.teaser-nav .item.hl { background: #e2001a; border-color: #70bee8; }
.teaser-nav .item:last-child { border-bottom: none; }
.teaser-nav .item h3 { font-size: 16px; line-height: 1.625em; font-weight: 600; color: #fff; margin: 0; margin-right: 161px; position: relative; top: 50%; @include translate(0,-50%); }
.teaser-nav .item .img { position: absolute; right: 14px; top: 50%; width: 153px; height: auto; -webkit-transform: translate(0,-50%); -moz-transform: translate(0,-50%); -ms-transform: translate(0,-50%); -o-transform: translate(0,-50%); transform: translate(0,-50%); }
.teaser-nav .item .img img { display: block; margin: 0 auto; max-height: 130px; }
.teaser-nav .item .img .pimcore_editable { width: 100%; background: white; }
.teaser-nav .item .img img ~ .pimcore_editable { position: absolute; left: 0; bottom: 16px; }

.teaser-project { margin: 0 auto; height: auto; text-align: center; }
.teaser-project .teaser-content { height: auto; padding: 36px 0; color: #fff; }
.teaser-project .teaser-content h1 { color: #fff; margin-bottom: 18px; }
.teaser-project .teaser-content .subhead { font-size: 18px; line-height: 1.3em; color: rgba(255,255,255,0.8); }
.teaser-project .teaser-content .img { display: inline-block; vertical-align: top; margin-bottom: 18px; max-width: 135px; }

.teaser-project.invert .teaser-content { color: rgba(0,0,0,0.8); }
.teaser-project.invert .teaser-content .subhead { color: rgba(0,0,0,0.8); }
.teaser-project.invert .teaser-content h1 { color: #000; }

.pimcore_area_entry {
  .teaser-content {
	height: auto;
	.head {
	  top: auto;
	  -webkit-transform: none;
	  -moz-transform: none;
	  -ms-transform: none;
	  -o-transform: none;
	  transform: none;
	}
  }
}

/* Hls */

.hls { margin: 0; color: #054a7e; font-weight: bold; text-align: center; }
.hls .item { display: block; line-height: 1.8em; font-size: 120%; }
.hls .item .num { color: #e2001a; }

/* Homepage buttons */
.homepage-buttons-container {
  padding: 18px 0;
  .row, .col-md-9 {
	margin: 0;
	padding: 0;
  }
}
.homepage-buttons {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  .homepage-button {
	width: 30%;
	text-align: center;
  }
  .btn {
	background: white;
    color: #054a7e;
	margin: 0 !important;
  }
  .cell {
    display: inline-block;
    .header-like, dt, .error-placement-wrapper {
      display: none;
    }
    dd {
      position: relative;
      .selector {
        color: #054a7e;
        border: 2px solid #e2001a;
        font-size: 15px;
        line-height: 18px;
        font-weight: bold;
        height: auto;
        span {
          height: auto;
          line-height: inherit;
          text-indent: 0;
          padding: 15px 45px 12px 27px;
          background-position-y: 23px;
        }
      }
      .select2 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100% !important;
        height: 100%;
		text-align: left;
        .select2-selection {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border: 2px solid #e2001a;
          background: white;
          .select2-selection__rendered {
            padding: 15px 0 0 27px;
            color: #054a7e;
            font-size: 15px;
            line-height: 18px;
            font-weight: bold;
          }
          .select2-selection__arrow {
            position: absolute;
            right: 0;
            top: 0;
            width: 36px;
            height: 100%;
            background: transparent url(../img/select.png) right 21px no-repeat;
            b {
              display: none;
            }
          }
        }
        &.select2-container--open .select2-selection__arrow, &:hover .select2-selection__arrow {
          transform-origin: 0 24px;
          transform: scaleY(-1);
        }
      }
    }
  }
}
@media (min-width: 1080px) and (max-width: 1240px) {
  .hls {
	font-size: 85%;
  }
  .homepage-buttons .homepage-button {
	width: auto;
  }
}
@media (max-width: 1079px) {
  .hls .item-list {
    display: flex;
    justify-content: space-around;
	.item {
	  width: 33%;
	}
  }
  .homepage-buttons-container {
    .col-md-3, .col-md-9 {
      width: 100%;
      float: none;
    }
  }
}
@media (max-width: 892px) {
  .hls .item-list {
    flex-direction: column;
    align-items: center;
	.item {
	  width: auto;
	}
  }
  .homepage-buttons {
	flex-wrap: wrap;
	.homepage-button {
	  width: auto;
	  margin: 5px 9px;
	  .btn {
		padding-top: 15px;
		padding-bottom: 12px;
	  }
	}
  }
}

/* News */

.news { margin: 0 auto; max-width: 1728px; }
.news .item-list { margin: 0 0 36px 0; display: flex; flex-wrap: wrap; }
.news .item { width: 25%; }
.layout-content .news .item { width: 33%; }
.news .item .img { position: relative; display: block; overflow: hidden; }
.news .item .img:after { content: ''; display: block; /*background: rgba(226,0,26,0.83);*/ background: rgba(0,0,0,0.5); position: absolute; left: 0; bottom: 0; width: 100%; height: 0; @include transition(all 0.2s); }
.news .item .play { width: 45px; height: 45px; background: #fff; position: absolute; left: 50%; top: 50%; margin-top: -23px; margin-left: -23px; z-index: 50; @include scale(0); @include transition(all 0.2s); }
.news .item .play:after { content: ''; position: absolute; left: 50%; top: 50%; margin-left: -6.75px; margin-top: -9px; width: 0; height: 0; border-style: solid; border-width: 9px 0 9px 14px; border-color: transparent transparent transparent #e2001a; }
.news .item .text { padding: 18px 27px; border-left: 2px solid #d5dce4; }
.news .item .text a:hover { color: #e2001a; }
.news .item .text p { margin-bottom: 0; }
.news .item:nth-child(4n+1) .text { border: none; }
.news .item .head { position: absolute; left: 0; bottom: 0; z-index: 20; display: block; background: rgba(0,0,0,0.5); width: 100%; padding: 15px; }
.news .item .date { color: #8ed8ff; margin-bottom: 6px; }
.news .item h3 { font-size: 15px; line-height: 1.5em; margin: 0; color: #fff; }
.news .item a.more { position: relative; padding-right: 18px; display: inline-block; vertical-align: top; margin-top: 9px; }
.news .item a.more:before { content: ''; display: inline-block; position: absolute; right: 0; top: 7px; width: 7px; height: 7px; border-right: 2px solid #e2001a; border-top: 2px solid #e2001a; transform: rotate(45deg); margin-right: 0.5em; display: block; }
.news .foot { text-align: center; }

.news .item .img:hover .head { background: none; }
.news .item .img:hover:after { height: 100%; }
.news .item .img:hover .date { color: #ffc9c9; }
.news .item .img:hover .play { @include scale(1); }

.news.news-hp .item .text { padding-bottom: 0; }
.news.news-hp .item-list { margin-bottom: 0; }

/* News detail */

.news-detail { margin: 0; }
.news-detail .head { font-weight: 600; border-bottom: 2px solid #d5dce4; padding-bottom: 18px; margin-bottom: 36px; }
.news-detail .head h1 { margin-bottom: 18px; }
.news-detail .image-and-perex { overflow-x: auto; }

/* Img box */

.img-box { position: relative; overflow: hidden; margin: 0 auto; max-width: 1728px; }
.img-box .img { width: 50%; height: 100%; position: absolute; left: 0; top: 0; background-size: cover !important; }
.img-box .img:after { content: ''; position: absolute; display: block; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); }
.img-box .img .head { position: absolute; right: 0; top: 50%; z-index: 30; width: 100%; max-width: 630px; padding-left: 14px; padding-right: 14px; @include translate(0,-50%); }
.img-box .img .head h2 { color: #fff; margin-bottom: 27px; }
.img-box .img .head .btn { color: #fff; background: #e2001a; border: none; }
.img-box .img .head .btn:hover { color: #e2001a; background: #fff; }
.img-box .img .suphead { font-size: 18px; line-height: 1.625em; margin-bottom: 5px; color: #8ed8ff; text-transform: uppercase; }
.img-box .text { font-size: 16px; line-height: 1.5em; color: #fff; width: 50%; margin-left: 50%; background: #054a7e; padding: 36px; min-height: 315px; }
.img-box .text p { margin-bottom: 36px; }
.img-box .text h3 { font-size: 16px; line-height: 1.625em; font-weight: 400; color: #8fd8ff; text-transform: uppercase; }
.img-box .text a { color: #fff; }
.img-box .text a:hover { color: #8fd8ff; }
.img-box .col2-set { color: #fff; position: relative; margin-left: -45px; }
.img-box .col2-set .col { padding-left: 45px; padding-right: 14px; }
.img-box .col2-set:after { content: ''; position: absolute; left: 50%; top: 0; width: 2px; height: 100%; background: #376c95; }

.goals { font-size: 15px; line-height: 1.625em; }
.goals li:before { font-size: 15px; color: #fff; }

.file-list li { line-height: 2.65em; padding-left: 36px; position: relative; background: none; }
.file-list li .icon { position: absolute; left: 0; top: 7px; width: 32px; height: 32px; }

/* Homepage */
.homepage-bottom-button {
  text-align: center;
  margin-top: 14px;
}

/* Partners */

.partners .head { float: left; max-width: 297px; text-align: left; padding-top: 18px; color: #2b5c89; }
.partners .head h3 { font-size: 18px; text-transform: uppercase; color: #2b5c89; }
.partners .imgs { max-width: 900px; float: right; margin-right: -14px; }
.partners .imgs img { padding: 0 14px; display: inline-block; vertical-align: top; }
.partners .item-list { text-align: center; margin-left: -1px; margin-bottom: -1px; }
.partners .item-list .item {
  width: 20%; padding-left: 18px; margin-bottom: 27px; text-align: center;
  a{
	display: inline-block; vertical-align: top;
	max-width: 250px; margin: 0 auto;
  }
}
.partners.small .item-list .item a{ max-width: none; }
.partners .item-list .item img { display: inline-block; vertical-align: top; }
.partners .item-list.a-l { text-align: left; }

@media screen and (min-width: 1080px) {
  .partners-container { max-width: none; }
  .partners.small .item-list .item { width: 14%; max-width: 172px; }
}

.partners.archive { margin-bottom: 36px; }
.partners.archive.side h3 { text-align: center; }
.partners.archive .item-list { text-align: left; margin-bottom: 0; }
.partners.archive .item a { display: block; height: 108px; line-height: 108px; /*padding: 0 20px; border: 1px solid rgba(0,0,0,0.2);*/ }
.partners.archive .item a img { display: inline-block; vertical-align: middle; }

.layout-sidebar .partners .item-list .item-list { margin-bottom: 0; }
.layout-sidebar .partners .item-list .item { width: 100%; margin-bottom: 0; }
.layout-sidebar .partners .item-list .item a { height: auto; line-height: 81px; }
.layout-sidebar .partners .item-list .item a img { max-height: 81px; width: auto; display: block; margin: 0 auto; }

/* Movies */

.movies { margin: 0 0 18px 0; }
.movies .section-head { margin-bottom: 36px; }
.movies .head { float: left; width: 252px; padding-top: 27px; border-top: 1px solid #d5dce4; }
.movies .head::after { content: ''; display: block; clear: both; }
.movies .head h2 {
    font-size: 22px; line-height: 1.5em; font-weight: 600; text-transform: uppercase; margin: 0; color: #e2001a; max-width: 207px;
    a{
        display: inline !important;
        color: inherit !important;
        background: inherit !important;
        border: 0 !important;
        margin: 0 !important;
        padding: 0 !important;
    }
}
.movies .head a { padding-left: 18px; position: relative; }
.movies .head a:before { content: ''; position: absolute; left: -2px; top: 50%; margin-top: -3px; display: inline-block; width: 0.5em; height: 0.5em; border-right: 0.15em solid #e2001a; border-top: 0.15em solid #e2001a; transform: rotate(45deg); display: block; }
.movies .head a.theme { text-decoration: none !important; }
.movies .head a.theme::before, .movies .head a.button::before { display: none; }
.movies .head a.button { display: block; float: left; clear: left; padding: 7px 18px; border: 1px solid #204b7c; background: #204b7c; margin-top: 8px; text-decoration: none !important; color: white; }
.movies .head a.button:hover { background: white; color: #204b7c; }
.movies .head a.button.step-by-step { background: #e2001a; border-color: #e2001a; }
.movies .head a.button.step-by-step:hover { background: white; color: #e2001a; }
.movies .head a.button.online { background: white; border-color: #e2001a; color: #e2001a; }
.movies .head a.button.online:hover { background: #e2001a; color: white; }
@media screen and (max-width: 576px) {
	.movies .head a.button { display: inline-block; float: none; clear: none; }
}
@media screen and (min-width: 891px) and (max-width: 1079px) {
	.movies .head a.button { display: inline-block; float: none; clear: none; }
}
.movies .item-list { display: flex; flex-wrap: wrap; }
.movies .item-list .item { width: 25%; }
.movies .item-list .item .play { width: 45px; height: 45px; background: #fff; position: absolute; left: 50%; top: 50%; margin-top: -23px; margin-left: -23px; z-index: 20; @include scale(0); @include transition(all 0.2s); }
.movies .item-list .item .play:after { content: ''; position: absolute; left: 50%; top: 50%; margin-left: -6.75px; margin-top: -8px; width: 0; height: 0; border-style: solid; border-width: 9px 0 9px 13px; border-color: transparent transparent transparent #e2001a; }
.movies .item-list .item .img { position: relative; display: block; }
.movies .item-list .item .img:after { content: ''; display: block; /*background: rgba(226,0,26,0.83);*/ background: rgba(0,0,0,0.5); position: absolute; left: 0; bottom: 0; width: 100%; height: 0; @include transition(all 0.2s); }
.movies .item-list .item .title { font-size: 14px; line-height: 18px; position: absolute; left: 0; bottom: 0; z-index: 20; width: 100%; margin: 0; color: #fff; text-align: center; padding: 16px 9px; background: rgba(0,0,0,0.7); }
.movies .item-list .item .title .num { color: #8ed8ff; }
.movies .item-list.full, .movies .item-list.medium { padding-left: 0; }
.movies .item-list.full .item { width: 20%; }
.movies .item-list.medium .item { width: 33.33%; }
.movies .foot { padding-top: 27px; text-align: center; }

.movies .item-list .item .img:hover:after { height: 100%; }
.movies .item-list .item .img:hover .title { background: none; }
.movies .item-list .item .img:hover .play { @include scale(1); }

.movies-related .item-list { text-align: center; }
.movies-related .item-list .item { text-align: left; }

.home-movies-block {
  border-top: 1px solid #d5dce4;
  width: 100%;
  max-width: 1728px;
  margin-left: auto;
  margin-right: auto;
  .container {
	margin-top: -1px;
  }
  .movies {
    .head {
      padding: 32px 12px;
      h2 {
        font-size: 150%;
      }
    }
    .item-list {
      background: white;
    }
    &.recommended-lectures {
      background: #0b6eab;
      .head {
        h2 {
          color: white;
        }
        a {
          color: white;
          &::before {
            border-color: white;
          }
        }
      }
    }
  }
}

/* Movie list */

.movie-list { margin: 0; border-top: 2px solid #d5dce4; }
.movie-list .item-list { margin: 0; }
.movie-list .item { margin-top: 27px; padding-bottom: 27px; border-bottom: 2px solid #d5dce4; width: 100%; }
.movie-list .item h2 { font-size: 22px; }
.movie-list .item .img { float: left; width: 135px; }
.movie-list .item .item-text { padding-left: 180px; }
.movie-list .item .item-text .more { position: relative; padding-left: 18px; }
.movie-list .item .item-text .more:after { content: ''; position: absolute; left: 0; top: 5px; width: 7px; height: 7px; border-right: 2px solid #e2001a; border-top: 2px solid #e2001a; transform: rotate(135deg); margin-right: 0.5em; display: block; @include transition(all 0.2s); }
.movie-list .item .item-text .more.active:after { transform: rotate(-45deg); top: 5px; }
.movie-list .item .item-text .li { position: relative; padding-left: 18px; }
.movie-list .item .item-text .li:after { content: ''; position: absolute; left: 0; top: 5px; width: 7px; height: 7px; border-right: 2px solid #e2001a; border-top: 2px solid #e2001a; transform: rotate(45deg); margin-right: 0.5em; display: block; }
.movie-list .item table { margin: 0; border-top: 1px solid #d5dce4; }
.movie-list .item table tbody tr:first-child td { padding-top: 18px; }
.movie-list .item table tbody tr.last td { padding-bottom: 18px; }
.movie-list .item table td { padding-top: 5px; padding-bottom: 5px; }
.movie-list .item table td.title { width: 162px; padding-right: 18px; font-weight: 600; }
.movie-list .item table td.price { font-size: 18px; color: #054a7e; padding-right: 18px; white-space: nowrap; }
.movie-list .item table td.price .num { font-weight: 600; }
.movie-list .item table tfoot tr { border-top: 1px solid #d5dce4; }
.movie-list .item table tfoot tr td { line-height: 48px; vertical-align: middle; padding-top: 18px; }

/* Lecture */

.box-lecture { max-width: 765px; margin: 0 auto; }
.box-lecture .box-head { font-size: 16px; line-height: 1.5em; background: #054a7e; padding: 14px 22px; color: #8ed8ff; }
.box-lecture .box-head a { color: #fff; }
.box-lecture .box-head .icon { width: 22px; height: 22px; top: 2px; margin-right: 2px; }
.box-lecture .box-content { background: #fff; padding: 27px; }
.box-lecture .box-content p { margin-bottom: 0; }
.box-lecture .box-content .img { width: 148px; float: left; }
.box-lecture .box-content .text { padding-left: 171px; }

.lecture-intro {
  text-align: center;
  margin-bottom: 27px;
  padding-top: 32px;
  h1 {
	margin-bottom: 11px;
  }
}

/*STYLED DOCUMENTS */
.styled-document, .theme-section {
	> .heading {
		padding-top: 27px;
		padding-bottom: 27px;
		h1, .h1 {
			font-size: 25px;
			color: #e2001a;
			font-weight: 600;
			a{
				text-decoration: none !important;
				color: inherit !important;
			}
		}

		.description {
			font-weight: 600;
			line-height: 1.7;
		  font-size: 1.1em;
		}

		.name > span {
			display: inline-block;
			color: white;
			height: 36px;
			line-height: 36px;
			position: relative;
			overflow: hidden;
			padding: 0 18px;

			span {
				display: inline-block;
				background: #054A7E;
				padding: 0 14px;
				div{
					display: inline-block !important;
					min-width: 108px !important;
				}
				&::after, &::before {
					position: absolute;

					top: 50%;
					margin-top: -36px;
					content: ' ';
					display: block;
					width: 0;
					height: 0;
					border: 18px solid transparent;
					border-top-width: 36px;
					border-bottom-width: 36px;
				}

				&::before {
					left: -18px;
					border-right-color: #054A7E;
				}

				&::after {
					right: -18px;
					border-left-color: #054A7E;
				}
			}
		}
	}
}
/* Theme Section */
.theme-section{
    >.contents{
        padding-top: 36px;
        padding-bottom: 36px;
        @media screen and (min-width: 804px) {
            padding-top: 67px;
        }
        .category-placeholder{
            text-transform: uppercase;
            padding: 7px;
            margin: 18px 0;
            background: #c0cad6;
            text-align: center;
        }
        hr{
            height: 2px;
            border:0;
            background: #c0cad6;
            margin-top: 22px;
            margin-bottom: 22px;
            @media screen and (min-width: 804px) {
                margin-top: 36px;
                margin-bottom: 36px;
            }
        }
        .description {
            hr{
                margin-top: 18px !important;
                margin-bottom: 18px !important;
            }
            ul ul, ol ol, ul ol, ol ul{
                margin-bottom: 0;
                margin-top: 5px;
            }
            ul {
                >li {
                    position: relative;
                    padding-left: 27px;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 3px;
                        top: 7px;
                        width: 7px;
                        height: 7px;
                        display: block;
                        background: #e2001a;
                    }
                }
            }
            ol{
                >li{
                    padding-left: 0;
                    &::before {
                        color: #e2001a;
                        position: static;
                    }
                }
            }
        }
        .category{
            margin-bottom: 22px;
            .lectures{
                margin: 14px -9px 0 -9px;
                /*&.has-opened .lecture.ajax-box{
                    display:none;
                }
                &.has-opened .lecture.ajax-box.opened{
                    display:block;
                }*/
                &::after{
                    content: ' ';
                    height:0;
                    display:block;
                    clear:both;
                }
            }
        }
        .level{
            margin-bottom: 36px;
            margin-top: 36px;
            >.description{
                margin-bottom: 22px;
            }
            &:first-child{
                margin-top: 0;
            }
        }
        .level+.level{
            margin-top: 0;
        }

    }
    .navigation{
        border: 2px solid #d5dce4;
        color: #054A7E;
        margin-bottom: 27px;
        strong{
            display:block;
            padding: 9px 18px;
            background: #d5dce4;
            text-transform: uppercase;
            a{
                text-decoration: none !important;
                color: inherit !important;
            }
        }
        ul{
            padding: 0;
            margin: 0;
            li{
                margin: 0;
                padding: 4px 0;
                span{
                    display:block;
                }
                a{
                    display: inline-block;
                    font-weight: 600;
                    border-left: 2px solid #d5dce4;
                    margin-left: -2px;
                    padding: 4px 0 4px 18px;
                    &:hover{
                        color:#e2001a;
                    }
                }
                li a{
                    font-size: 0.9em;
                    &::before{
                        content: ' ';
                        display:inline-block;
                        width: 6px;
                        height: 6px;
                        border: 2px solid #d5dce4;
                        vertical-align: middle;
                        margin-right: 7px;
                    }
                }
                &.active>a, &.active>span>a{
                    border-color:#e2001a;
                    color:#e2001a;
                }
            }
        }
        >ul{
            padding: 11px 0;
            >li span{
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    right: 9px;
                    top: 50%;
                    margin-top: -9px;
                    width: 7px;
                    height: 7px;
                    border-right: 2px solid #e2001a;
                    border-top: 2px solid #e2001a;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    margin-right: 0.5em;
                    display: block;
                }
            }
        }
    }
    .lecture.ajax-box{
        color: #CECECE;
        background: #054A7E;
        width: 100%;
        max-width: 288px;
        border: 9px solid white;
        position: relative;
        box-sizing: border-box;
        @media screen and (min-width: 804px) {
            width: 50%;
            max-width: none;
            float:left;
            &:nth-child(2n+1){
                clear:both;
            }
        }
        @media screen and (min-width: 1036px) {
            width: 33.33%;
            &:nth-child(2n+1){
                clear:none;
            }
            &:nth-child(3n+1){
                clear:both;
            }
        }
        a, h4, h5{
            color: white;
        }
        .box-content, .close{
            display:none;
        }
        .close{
            position: absolute;
            padding: 4px 9px;
            color: white;
            right: 0;
            bottom: 0;
            background: #043D68;
            cursor:pointer;
            text-transform: uppercase;
            font-size: 0.9em;
            &::before{
                content: '×';
                display:inline-block;
                margin: 0 5px 0 0;
                font-size: 1.5em;
            }
        }
        .preview{
            display:block;
            cursor:pointer;
            width: 100%;
            padding-bottom: 11px;
            text-decoration: none !important;
            font-size: 1.1em;
            position: relative;
            >a{
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                text-indent: -9999px;
                overflow: hidden;
            }
            .thumb{
                margin-bottom: 11px;
            }
            >strong,>small,>div{
                display:block;
                padding: 0 11px;
            }
            >small, >div {
                color: #6DDAFF;
            }
            >strong{
                color: white;
            }
            >div{
                font-size: 0.8em;
                p, ul, ol{
                    margin: 0;
                    padding: 0;
                }
            }
        }
        &.loading{
            position: relative;
            &::after{
                position: absolute;
                content: ' ';
                display:block;
                width: 100%;
                height: 100%;
                top: 0;
                left:0;
                background: rgba(0,0,0,0.85) url('../img/fancybox/fancybox_loading.gif') center center no-repeat;
            }
        }
        &.opened{
            width: 100% !important;
            max-width: none !important;
            float:none !important;
            clear: both;
            .box-content, .close{
                display:block;
            }
            .preview{
                display:none;
            }
        }
        .box-content{
            padding: 0 18px 18px 18px;
            @media screen and (min-width: 1036px) {
                padding: 9px 27px 27px 27px;
            }
            h4{
                font-weight: bold;
                font-size: 19px;
            }
            .row>*{
                padding-top: 18px;
            }
            .info, h5, .description strong{
                color: #6DDAFF;
            }
            .description{
              margin-top: 18px;
            }
            .info{
                display:block;
                margin: 9px 0 18px 0;
            }
            .link{
                margin-top: 36px;
            }
            .actions{
                margin-bottom: 20px;
                &::after{
                    content: ' ';
                    display: block;
                    height: 0;
                    clear: both;
                }
            }
			.btn {
				min-width: 162px;
				width: 100%;
				max-width: 288px;
				white-space: nowrap;
				cursor:pointer;
				margin: 18px 0 5px 0;
				padding: 13px 23px;
			    color: #fff;
			    border: 2px solid #7cc1e9;
			    background: none;
			    text-align: center;
			    &:hover{
				    background: #7cc1e9;
				}
				@media screen and (min-width: 377px) {
				    width: 56%;
				}
				&.play{
				    border: 0;
					color: white;
					background: #e2001a !important;
					&:hover{
					    background: #6DDAFF !important;
					}
				    .icon-play{
						color: white;
					}
					&.secondary{
						margin-top: 5px;
						margin-bottom: 5px;
						padding: 11px 22px;
						background: transparent !important;
						border: 2px solid #6DDAFF;
						@media screen and (min-width: 377px) {
							float:left;
							margin-right: 1%;
						}
						&:hover{
						    background: #6DDAFF !important;
						}
					}
				}
				&.play.secondary+.secondary{
					@media screen and (min-width: 377px) {
						margin-right: 0;
						margin-left: 1%;
					}
				}
			}
        }
    }
    .theme-list{
        padding-top: 27px;
        padding-bottom: 27px;
        ul{
            margin-top: 22px;
            li{
                padding:0;
                display:block;
                width: 100%;
                margin: 0;
                @media screen and (min-width: 378px) {
                    width: 50%;
                    float:left;
                }
                @media screen and (min-width: 391px) {
                    width: 33.33%;
                }
                @media screen and (min-width: 891px) {
                    width: 25%;
                }
                @media screen and (min-width: 1080px) {
                    width: 20%;
                }
                a{
                    display:block;
                    position: relative;
                    strong{
                        display:block;
                        font-size: 15px;
                        line-height: 18px;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        color: white;
                        padding: 16px 9px;
                        background: rgba(0,0,0,0.7);
                        z-index:2;
                    }
                    &::after{
                        content: '';
                        display: block;
                        background: rgba(0,0,0,0.5);
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 0;
                        -webkit-transition: all 0.2s;
                        -moz-transition: all 0.2s;
                        -o-transition: all 0.2s;
                        transition: all 0.2s;
                        z-index:1;
                    }
                    &:hover{
                        strong {
                            background: transparent;
                        }
                        &::after{
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    .thumb{
        display:block;
        position: relative;
        padding-top: 63.8%;
        background: rgba(0,0,0,0.4);
        img{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom:0;
            margin: auto;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}

/* Lecture theme page */
.lecture-theme-page {
  .block.alt-2 {
	padding-top: 32px;
  }
  .theme-parent-title {
	font-weight: bold;
	font-size: 120%;
  }
  .theme-page-lectures {
	padding-top: 32px;
	padding-bottom: 32px;
	.container {
	  max-width: none;
	}
  }
}

/* Lecture boxes */
.lecture-area {
  margin: 14px -9px 40px -9px;
  .lecture-box {
	display: inline-block;
	vertical-align: top;
	width: 33.33%;
	background: #054A7E;
	color: #CECECE;
	border: 9px solid white;
	box-sizing: border-box;
	@media all and (max-width: 1152px) {
		width: 50%;
	}
	@media all and (max-width: 890px) {
	  width: 100%;
	  max-width: 288px;
	}
	.preview {
	  cursor: pointer;
	  font-size: 1.1em;
	  .thumb {
		padding-top: 63.8%;
		background: rgba(0, 0, 0, 0.4);
		position: relative;
		img {
		  position: absolute;
		  left: 0;
		  right: 0;
		  top: 0;
		  bottom: 0;
		  margin: auto;
		  max-width: 100%;
		  max-height: 100%;
		}
	  }
	  .info {
		padding: 11px;
		line-height: 1.625em;
		font-size: 0.8em;
		color: #6DDAFF;
		h3 {
		  color: white;
		  font-size: 1.25em;
		  line-height: 1.625em;
		}
	  }
	}
	.content {
	  position: relative;
	  display: none;
	  padding: 27px 40px 40px;
	  h4, strong {
		color: #6DDAFF;
	  }
	  h3, a, li::before {
		color: white;
	  }
	  h3 {
		font-size: 1.4em;
	  }
	  ul li {
		position: relative;
		&::before {
		  content: '';
		  position: absolute;
		  left: 3px;
		  top: 7px;
		  width: 7px;
		  height: 7px;
		  display: block;
		  background: white;
		}
	  }
	  .image {
		float: right;
		margin-left: 22px;
		margin-bottom: 11px;
		max-width: 40%;
		@media all and (max-width: 691px) {
		  float: none;
		  margin-right: 0;
		  max-width: none;
		  width: 100%;
		}
	  }
	  .btn {
		color: #fff;
		border: solid 2px #7cc1e9;
		background: none;
		margin-right: 11px;
		&:hover {
		  background: #7cc1e9;
		}
		&.play {
		  border-color: #e2001a;
		  background: #e2001a;
		  color: white;
		  cursor: pointer;
		  &:hover {
			background: #6DDAFF;
			border-color: #6DDAFF;
		  }
		}
	  }
	  .close {
		position: absolute;
		padding: 4px 9px;
		color: white;
		right: 0;
		bottom: 0;
		background: #043D68;
		cursor: pointer;
		text-transform: uppercase;
		font-size: 0.9em;
		&::before {
		  content: '×';
		  display: inline-block;
		  margin: 0 5px 0 0;
		  font-size: 1.5em;
		}
	  }
	  &::after {
		content: "";
		display: block;
		clear: both;
	  }
	}
	&.opened {
	  width: 100%;
	  max-width: none;
	  clear: left;
	  float: none;
	  .preview {
		display: none;
	  }
	  .content {
		display: block;
	  }
	}
  }
}
.lecture-area-admin-header {
  position: relative;
  padding-left: 99px;
  min-height: 90px;
  img {
	position: absolute;
	left: 0;
	top: 0;
  }
}

/* Lecture embedded video */
.lecture-embedded-video {
  margin: 14px 0;
  max-width: 756px;
  .preview {
	cursor: pointer;
	position: relative;
	.thumb {
	  padding-top: 59.125%;
	  background: black;
	  position: relative;
	  img {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		max-width: 100%;
		max-height: 100%;
	  }
	}
	> a {
	  position: absolute;
	  left: 0;
	  top: 0;
	  width: 100%;
	  height: 100%;
	  text-align: center;
	  padding-top: calc(29.5625% - 27px);
	  .btn.play {
		background: #e2001a;
		color: white;
	  }
	  &:hover .btn.play {
		background: white;
		color: #e2001a;
	  }
	}
  }
  .jwplayer {
	margin: 0 auto;
  }
}

.pimcore_editable {
  ul li {
	position: relative;
	&::before {
	  content: '';
	  position: absolute;
	  left: 3px;
	  top: 7px;
	  width: 7px;
	  height: 7px;
	  display: block;
	  background: #e2001a;;
	}
  }
}

.admin-noedit {
  display: none;
}

@media all and (min-width: 891px) {
  .lecture-area-wide {
	.lecture-box {
	  width: 100% !important;
	  max-width: none !important;

	  .preview {
		.thumb {
		  float: left;
		  width: 288px;
		  max-width: 40%;
		  height: 100%;
		  max-height: 184px;
		  padding-top: calc(min(25.5%, 184px));
		  margin-bottom: 0;
		  margin-right: 22px;
		}
		&::after {
		  content: "";
		  display: block;
		  clear: left;
		}
	  }
	}
  }
}

/* Filter lectures */

.filter { background: #fff; padding: 14px; min-height: 72px; margin: 0 auto 27px auto; position: relative; width: 100%; max-width: 1460px; }
.filter .head { font-size: 18px; line-height: 45px; font-weight: 600; color: #054a7e; float: left; width: 162px; }
.filter .head .icon { width: 25px; height: 25px; margin-right: 9px; vertical-align: middle; position: relative; top: -3px; }
.filter .fields {  padding-left: 162px; padding-right: 135px; }

.col2-l-set .col-2 .filter, .layout-content .filter { padding: 0; }

.filter-lectures { background: transparent; }

/* Projects */

.projects { margin: 0; }
.projects .section-head { margin-top: 16px; margin-bottom: 20px; text-align: left; }
.projects .item-list { margin: 0 0 0 -3px; text-align: left; display: flex; flex-wrap: wrap; }
.projects .item { width: calc(25% - 3px); max-width: 324px; margin-left: 3px; margin-bottom: 3px; text-align: left; background: #054a7e; box-shadow: 0 0 7px #AAA; }
.projects .item-inner { text-decoration: none; display: block; color: white; }
.projects .item .img { display: block; position: relative; margin: 14px 16px 0; }
.projects .item .img:after { content: ''; display: block; /*background: rgba(226,0,26,0.83);*/ background: rgba(0,0,0,0.5); position: absolute; left: 0; bottom: 0; width: 100%; height: 0; @include transition(all 0.2s); }
.projects .item .text { padding: 18px 16px 14px 16px; min-height: 117px; position: relative; }
.projects .item .text h2 { font-size: 17px; line-height: 1.3em; color: white; }
.projects .item .text p { margin: 0; font-size: 13px; }
.projects .foot { text-align: center; padding-top: 18px; }

.projects .item-inner:hover .img:after { height: 100%; }

/* Activities */

.activities { margin: 40px 0; }
.activities .section-head { margin-top: 36px; margin-bottom: 22px; }
.activities .item-list { margin: 0; text-align: center; }
.activities .item { width: 20%; padding-left: 0; margin-bottom: 0; text-align: left; }
.activities .item-inner { text-decoration: none; display: block; color: #252525; }
.activities .item .img { display: block; position: relative; }
.activities .item .img:after { content: ''; display: block; /*background: rgba(226,0,26,0.83);*/ background: rgba(0,0,0,0.5); position: absolute; left: 0; bottom: 0; width: 100%; height: 0; @include transition(all 0.2s); }
.activities .item .img .label { position: absolute; z-index: 10; right: 13px; bottom: 13px; width: 56px; height: 56px; overflow: hidden; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; background: black; display: flex; justify-content: center; align-items: center; }
.activities .item .img .label img { max-width: 48px; max-height: 48px; }
.activities .item .text { border-left: 2px solid #c0cad6; padding: 12px; }
.activities .item:nth-child(5n+1) .text { border-left: none; }
.activities .item .text h2 { font-size: 14px; line-height: 1.3em; color: #054a7e; }
.activities .item .text p { margin: 0; }

.activities .item-inner:hover .text { background: #fff; }
.activities .item-inner:hover .img:after { height: 100%; }

/* Learning materials */

.learning-materials { margin: 0; }
.learning-materials .head { margin-bottom: 27px; }
.learning-materials .head .subhead { font-size: 18px; font-style: italic; color: #054a7e; padding-top: 5px; }
.learning-materials .head .subhead .icon { margin-right: 14px; color: #e2001a; top: 2px; }
.learning-materials .item-list { margin-left: -9px; display: flex; flex-wrap: wrap; }
.learning-materials .item { width: 33.33%; padding-left: 9px; margin-bottom: 9px; }
.learning-materials .item h3 { color: #054a7e; margin-top: 0; padding-right: 36px; font-size: 17px; }
.learning-materials .item h3 .red { color: #e2001a; }
.learning-materials .item .more .icon { width: 14px; height: 14px; margin-right: 9px; color: #e2001a; top: 6px; }
.learning-materials .item .subtitle { font-weight: 600; margin: 18px 0; }
.learning-materials .item .subtitle .activity-recommended-age .value { color: #e2001a; }
.learning-materials .item .activity-duration .red { color: #e2001a; }
.learning-materials .item .icon-lock { position: absolute; right: 18px; top: 20px; color: #e2001a; }
.learning-materials .item-inner { /*border-bottom: 2px solid #ccd3dc; padding: 20px 0;*/border: 2px solid #ccd3dc; padding: 12px 9px 0 12px; position: relative; font-size: 90%; }
.learning-materials .item-inner .entry { margin-bottom: 0; }
.learning-materials .activity-normal .item-inner { background: #fdf3f4; }
.learning-materials .activity-related .item-inner { background: #fdf3f4; border-color: #8bbcdd; }
.learning-materials .activity-other .item-inner { background: #f1f5ff; }
.learning-materials .activity-online .item-inner { background: #f4f5f9; border-color: #8bbcdd; }

/* Additional materials */

.additional-materials { margin: 0; }
.additional-materials .head { margin-bottom: 27px; }
.additional-materials .head .subhead { font-size: 18px; font-style: italic; color: #054a7e; }
.additional-materials .item-list { margin-left: -54px; }
.additional-materials .item { width: 100%; padding-left: 54px; position: relative; }
.additional-materials .item h3 { color: #054a7e; margin-top: 0; }
.additional-materials .item .subtitle { font-weight: 600; margin: 0; }
.additional-materials .item .icon-lock { position: absolute; right: 0; top: 50%; margin-top: -9px; color: #e2001a; }
.additional-materials .item-inner { border-bottom: 2px solid #ccd3dc; padding: 18px 27px 18px 0; }
/*.additional-materials .item:nth-child(1) .item-inner { border-top: 2px solid #ccd3dc; }
.additional-materials .item:nth-child(2) .item-inner { border-top: 2px solid #ccd3dc; }*/

/* Box movie */

.box-movie { margin: 0; }
.box-movie .img { margin: 0; position: relative; }
.box-movie .img img { position: relative; z-index: 10; }
.box-movie .text { background: #054a7e; color: #fff; padding: 32px; }
.box-movie .text h3 { font-size: 15px; font-weight: 400; margin-bottom: 5px; text-transform: uppercase; color: #8fd8ff; }
.box-movie .text .btn { color: #fff; border-color: #7cc1e9; background: none; margin-bottom: 22px; width: 100%; text-align: center; }
.box-movie .text .btn:hover { border-color: #7cc1e9; background: #7cc1e9; }
.box-movie .text .btn.alt { color: #fff; border-color: #e2001a; background: #e2001a; }
.box-movie .text .btn.alt:hover { border-color: #7cc1e9; background: #7cc1e9; }
.box-movie .text a { color: #fff; }

#interactive-areas-container { display:none; }
.video-player-interactive-area-template { display: none; }
.video-player-interactive-area {
    display: none;
    position: absolute !important;
    background-color: rgba(255,255,255,0.0) !important;
    border-color: rgba(255,255,255,0.4) !important;
    border-width: 2px !important;
    border-style: solid !important;
    border-radius: 9px !important;
    z-index: 9999 !important;
}
.jw-icon-cc::before {
    content: ' ' !important;
    background: url("../images/subtitles.png");
    width: 20px;
    height: 18px;
    display: inline-block;
    background-size: 100% 100%;
    vertical-align: middle;
    margin-top: -2px;
}
.jw-icon-cc:hover::before{
    opacity: 1 !important;
}
.jw-icon-cc.jw-off{
    opacity: 0.7;
}
.jw-icon-cc svg {
  display: none !important;
}
.jw-nextup-container { display: none !important; }
.jw-related-dock-btn { display: none !important; }

/* Topic's related */

.topic-related { margin: 0; }
.topic-related h2 { margin-bottom: 27px; }
.topic-related .item { padding-bottom: 18px; border-bottom: 2px solid #ccd3dc; }
.topic-related .img { float: left; }
.topic-related .text { padding-left: 180px; }

.movie-tag .item { font-size: 18px; color: #fff; background: #054a7e; height: 44px; line-height: 44px; padding: 0 18px; position: absolute; left: 50%; bottom: -22px; @include translate(-50%,0); }
.movie-tag .item:before { content: ''; position: absolute; left: -9px; top: 0; width: calc(100% + 20px); height: 50%; border-style: solid; border-width: 0 9px 22px 9px; border-color: transparent transparent #054a7e transparent; z-index: -1; }
.movie-tag .item:after { content: ''; position: absolute; left: -9px; bottom: 0; width: calc(100% + 20px); height: 50%; border-style: solid; border-width: 22px 9px 0 9px; border-color: #054a7e transparent transparent transparent; z-index: -1; }

/* Post list */

.post-list { margin: 0; }
.post-list .item-list { margin-left: -27px; }
.post-list .item { margin: 0; padding-left: 27px; width: 100%; }
.post-list .item-inner { border-bottom: 2px solid #ccd3dc; padding-bottom: 36px; margin-bottom: 36px; }
.post-list .item .img { float: left; width: 252px; position: relative; }
.post-list .item .img .label { position: absolute; z-index: 10; right: 13px; bottom: 13px; width: 56px; height: 56px; overflow: hidden; border-radius: 50%; background: black; display: flex; justify-content: center; align-items: center; }
.post-list .item .img .label img { max-width: 48px; max-height: 48px; }
.post-list .item .text { padding-left: 284px; }
.post-list .item .text ul li { position: relative; padding-left: 27px; }
.post-list .item .text ul li:before { content: ''; position: absolute; left: 3px; top: 8px; width: 7px; height: 7px; display: block; background: #e2001a; }
.post-list .item .text h2 { font-size: 18px; line-height: 1.3em; color: #054a7e; margin-bottom: 18px; }
.post-list .item .text .more { padding-left: 18px; position: relative; }
.post-list .item .text .more:before { content: ''; position: absolute; left: 0; top: 15px; margin-top: -9px; width: 0.45em; height: 0.45em; border-right: 0.15em solid #e2001a; border-top: 0.15em solid #e2001a; transform: rotate(45deg); margin-right: 0.5em; display: block; }
.post-list .item .text .disabled{color: #e2001a;}

.post-list .item:last-child .item-inner { border-bottom: none; padding-bottom: 0; margin-bottom: 0; }

/* Photogallery */

.photogallery { margin: 0 0 36px 0; }
.photogallery .item-list { margin: 0; display: flex; flex-wrap: wrap; }
.photogallery .item { width: 33.33333%; }
.photogallery .item .img { position: relative; display: block; }
.photogallery .item .img:after { content: ''; display: block; /*background: rgba(226,0,26,0.83);*/ background: rgba(0,0,0,0.5); position: absolute; left: 0; bottom: 0; width: 100%; height: 0; @include transition(all 0.2s); }
.photogallery .item .text { padding: 18px 18px 9px 18px; border-left: 2px solid #d5dce4; }
.photogallery .item .text a:hover { color: #e2001a; }
.photogallery .item:nth-child(3n+1) .text { border: none; }
.photogallery .item .head { position: absolute; left: 0; bottom: 0; z-index: 20; display: block; background: rgba(0,0,0,0.5); width: 100%; padding: 18px 18px; }
.photogallery .item .head h3 { font-size: 15px; line-height: 1.5em; margin: 0 0 5px 0; color: #fff; }
.photogallery .item .head .num { color: #8ed8ff; margin: 0; }
.photogallery .item .head .num .icon { color: #e2001a; top: 2px; margin-right: 9px; }

.photogallery .item .img:hover .head { background: none; }
.photogallery .item .img:hover .num { color: #fff; }
.photogallery .item .img:hover .num .icon { color: #fff; }
.photogallery .item .img:hover:after { height: 100%; }

/* Photogallery detail */

.photogallery-detail { margin: 0; }
.photogallery-detail .head { font-weight: 600; padding-bottom: 9px; }
.photogallery-detail .head h1 { margin-bottom: 18px; }
.photogallery-detail .description { border-bottom: 2px solid #d5dce4; padding-bottom: 9px; margin-bottom: 36px; }
.photogallery-detail .item-list { margin-left: -9px; display: flex; flex-wrap: wrap; }
.photogallery-detail .item { padding-left: 9px; margin-bottom: 9px; width: 25%; }
.photogallery-detail .foot { padding-top: 18px; }

/* User type */

.user-type { margin: 0; }
.user-type .section-head { margin-bottom: 36px; }
.user-type .item-list { margin-left: -27px; margin-bottom: 36px; display: flex; flex-wrap: wrap; }
.user-type .item { padding-left: 27px; width: 33.33333%; text-align: center; }
.user-type .item h3 { min-height: 46px; margin-bottom: 18px; }
.user-type .item p { margin: 0; }
.user-type .item-inner { border: 2px solid #eaedf1; padding: 12px 8px; display: block; text-decoration: none; }
.user-type .item-inner:hover { background: #eaedf1; }

/* Side nav */

.side-nav { border: 2px solid #ccd3dc; padding: 18px 0; margin-bottom: 36px; max-width: 324px; position: relative;}
.side-nav.fixed { position: fixed; top: 0; }
.side-nav ul { margin: 0; }

.side-nav ul li {
	position: relative;
	padding: 0;
	background: none;
	margin: 0;
	width: 100%;
	@media all and (max-width: 691px) {
		cursor: pointer;
		&.empty::after{
			border-color: #ccd3dc;
		}
		a{
			padding-left: 9px !important;
		}
		a::before {
			display: none !important;
		}
		a:hover{
			text-decoration: underline !important;
		}
		li{
			padding-left: 14px;
		}
		ul{
			margin-bottom: 9px;
		}
		li::after{
			display:none !important;
		}
	    .side-nav-title{
		    padding-left: 9px;
		    font-size: 1.1em;
		}
	    ul .side-nav-title + ul {
		    display: block;
		}
	}
	&.open{
		&::after{
			transform: rotate(135deg);
		}
		>ul{
			display:block;
		}
	}
	a {
		font-size: 16px;
		line-height: 1.5em;
		padding: 9px 45px 9px 27px;
		display: block;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			right: 22px;
			top: 18px;
			width: 7px;
			height: 7px;
			border-right: 2px solid #e2001a;
			border-top: 2px solid #e2001a;
			transform: rotate(45deg);
			margin-right: 0.5em;
			display: block;
		}
		@media all and (max-width: 691px){
			padding: 9px;
			margin-right: 36px;
			&::after {
				display:none;
			}
		}
	}

	&.active > a {
		color: #e2001a;
		position: relative;

		&::before {
			content: '';
			display: block;
			position: absolute;
			left: -2px;
			top: 0;
			width: 2px;
			height: 100%;
			background: #e2001a;
		}

		&::after {
			right: 22px;
		}
	}
}
.side-nav .trigger{display:none};

.side-nav ul.scrollspy li.active a { background: #fce5e8; }
.side-nav ul.scrollspy li a:after { content: none; }
.side-nav ul.scrollspy li a .icon { position: absolute; right: 22px; top: 18px; width: 14px; height: 14px; color: #e2001a; }

.side-nav ul ul li a { font-size: 15px; padding: 5px 40px; }
.side-nav ul ul li a:before { content: ''; position: absolute; left: 25px; top: 12px; width: 7px; height: 7px; border: 2px solid #ccd3dc; }
.side-nav ul ul li a:after { content: none; }

.side-nav-title { font-size: 1.25em; font-weight: bold; color: #e2001a; padding: 14px 23px 5px; display: block }

/* Side info */

.side-info { margin-bottom: 36px; }
.side-info ul { margin: 0; }
.side-info ul li { line-height: 1.9em; padding: 0 0 0 45px; margin: 0 0 18px 0; background: none; position: relative; overflow: hidden; text-overflow: ellipsis; }
.side-info ul li .icon { position: absolute; left: 0; top: 0; width: 27px; height: 27px; color: #e2001a; }

/* Side gallery */

.side-gallery { margin-bottom: 18px; }
.side-gallery .img { position: relative; display: block; }
.side-gallery .title { font-size: 15px; line-height: 27px; position: absolute; left: 0; bottom: 0; margin: 0; width: 100%; background: rgba(0,0,0,0.5); padding: 11px 18px; color: #fff; }
.side-gallery .title .num { font-weight: 400; color: #8ed8ff; }
.side-gallery .title .icon { top: -2px; color: #e40e27; width: 27px; height: 27px; margin-right: 5px; }

/* Persons */

.persons { margin-bottom: 36px; }
.persons h3 { margin-bottom: 18px; }
.persons .item-list { margin-left: -27px; margin-bottom: -36px; display: flex; flex-wrap: wrap; }
.persons .item { padding-left: 27px; width: 50%; margin-bottom: 36px; }
.persons .item .img { float: left; width: 90px; height: 90px; }
.persons .item .text { font-size: 15px; padding-left: 112px; }
.persons .item .name { font-size: 15px; font-weight: 600; color: #e2001a; margin: 0; }
.persons .item .job { margin: 0; color: #737373; }

.persons .item-list.set-1 .item { width: 100%; }

/* Cart */

.cart { margin: 0; }
.cart h3 { margin-bottom: 27px; }

.cart-nav { margin: 0 0 36px 0; }
.cart-nav ul { margin: 0; }
.cart-nav ul li { width: 33.33333%; float: left; padding: 0; }
.cart-nav ul li a { line-height: 27px; font-weight: 600; display: block; text-align: center; border: 2px solid #054a7e; border-left: none; text-decoration: none; padding: 14px 18px; cursor: default; }
.cart-nav ul li.done a { cursor: pointer; text-decoration: underline; }
.cart-nav ul li.done a:hover { text-decoration: none; }
.cart-nav ul li.done a:before { content: '✓'; width: 27px; height: 27px; line-height: 27px; display: inline-block; vertical-align: top; background: #e2001a; color: #fff; position: relative; top: -2px; margin-right: 9px; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; }
.cart-nav ul li.active a { /*background: #e2001a; color: #fff;*/ background: #054a7e; border-color: #054a7e; color: #fff; cursor: default; }
.cart-nav ul li:first-child a { border-left: 2px solid #054a7e; }

.cart-content { max-width: 864px; margin: 0 auto; }

.table-cart { margin: 0 0 27px 0; width: 100%; }
.table-cart th { border-top: 2px solid #d5dce4; padding: 18px; vertical-align: middle; text-align: left; background: #f1f4f7; }
.table-cart td { border-top: 2px solid #d5dce4; padding: 18px; vertical-align: middle; }
.table-cart td .img { margin: 0 18px 0 0; float: left; }
.table-cart td .text { padding-left: 162px; }
.table-cart td .title { font: 600 18px/1.3em 'Merriweather',sans-serif; color: #e2001a; margin: 0 0 9px 0; }
.table-cart td.val input.text { max-width: 63px; text-align: right; }
.table-cart td.price { font-size: 18px; font-weight: 600; text-align: left; color: #e2001a; }
.table-cart td.total { font-size: 15px; font-weight: 600; text-align: left; color: #e2001a; white-space: nowrap; }
.table-cart td.total .val { font-size: 25px; }
.table-cart .remove a { font-size: 22px; width: 27px; height: 27px; line-height: 27px; text-align: center; float: right; background: #054a7e; color: #fff; display: inline-block; vertical-align: top; text-decoration: none; }
.table-cart .remove a:hover { background: #e2001a; }

.cart-actions { text-align: right; line-height: 48px; padding-top: 27px; border-top: 2px solid #d5dce4; }
.cart-actions .back { float: left; }

.table-summary { margin: 0 0 27px 0; width: 100%; }
.table-summary th { border-top: 2px solid #d5dce4; padding: 18px; vertical-align: top; text-align: left; font-weight: 600; }
.table-summary td { border-top: 2px solid #d5dce4; padding: 18px; vertical-align: top; }

/* Search results */

.search-results { margin: 0; }
.search-results .item-list { margin: 0 0 32px 0; border-top: 5px solid #e9e9e9; border-bottom: 5px solid #e9e9e9;  }
.search-results .item { margin: 0; }
.search-results .item h3 { color: #054a7e; }
.search-results .item-inner { border-bottom: 1px solid #d8d9d9; padding: 27px; }
.search-results .item:last-child .item-inner { border: none; }

.search-results-top .results { font-size: 18px; line-height: 1.5em; margin-bottom: 27px; }
.search-results-top .results .info { font-size: 18px; line-height: 1.5em; margin: 0; }
.search-results-top .results .hl { font-weight: 400; color: #000; }
.tag-list { margin-bottom: 72px; }
.tag-list ul { margin: 0 0 0 -5px; }
.tag-list li { padding-left: 5px; }
.tag-list li a.active { color: #e41028; }
.tag-list li .pipe { color: #e41028; padding-left: 4px; padding-right: 4px; }

/* Login */

.login h1 { margin-bottom: 18px; }

/* Posts inline */

.posts-inline { margin: 0; }
.posts-inline .item-list { margin-left: -27px; display: flex; flex-wrap: wrap; }
.posts-inline .item { width: 33.33333%; padding-left: 27px; margin-bottom: 27px; }
.posts-inline .item h3 { font-size: 18px; line-height: 1.3em; }
.posts-inline .item h3 a { color: #e2001a; text-decoration: none; }
.posts-inline .item .img { height: 185px; margin-bottom: 18px; display: block; }
.posts-inline .item .img img { max-height: 185px; }
.posts-inline .item .more { position: relative; }
.posts-inline .item .more.active:before { content: ''; position: absolute; left: 90px; bottom: -30px; z-index: 20; width: 0; height: 0; border-style: solid; border-width: 0 14px 14px 14px; border-color: transparent transparent #ccd3dc transparent; }
.posts-inline .item .more.active:after { content: ''; position: absolute; left: 88px; bottom: -34px; z-index: 30; width: 0; height: 0; border-style: solid; border-width: 0 15px 15px 15px; border-color: transparent transparent #fff transparent; }
.posts-inline .item .more .text { position: relative; padding-left: 18px; }
.posts-inline .item .more .text:before { content: '+'; position: absolute; left: 0; top: -4px; color: #e2001a; font-size: 22px; font-weight: 600; text-decoration: none; }

.posts-inline .item-hidden { width: 100%; padding-left: 27px; margin-bottom: 27px; display: none; }
.posts-inline .item-hidden.active { display: block; }
.posts-inline .item-hidden .item-inner { border: 2px solid #ccd3dc; padding: 22px; position: relative; }
.posts-inline .item-hidden .item-inner .img { padding: 0 27px 27px 0; float: left; }
.posts-inline .item-hidden .text h2 { font-size: 27px; line-height: 1.2em; }
.posts-inline .item-hidden .text p:last-child { margin-bottom: 0; }
.posts-inline .item-hidden .text::after { content: ''; display: block; clear: both; }
.posts-inline .item-hidden .close { font-size: 27px; line-height: 40px; color: #e2001a; background: #fff; text-decoration: none; position: absolute; right: -2px; top: -2px; width: 45px; height: 45px; display: block; text-align: center; border: 2px solid #ccd3dc; }
.posts-inline .close-link { background: #054a7e; color: #fff; display: inline-block; vertical-align: top; padding: 2px 9px 2px 22px; margin-top: 18px; text-decoration: none; position: relative; }
.posts-inline .close-link:hover { background: #e2001a; }
.posts-inline .close-link:before { content: '×'; font-size: 18px; position: absolute; left: 3px; top: 50%; width: 18px; height: 18px; margin-top: -11px; text-align: center; line-height: 18px; }

.posts-inline-wide {
  .item:not(.item-hidden) {
	width: 100%;
	.item-inner {
	  .img {
		float: left;
		height: auto;
		img {
		  max-height: none;
		}
	  }
	  div.text {
		padding-top: 6px;
		padding-left: 380px;
	  }
	  &::after {
		content: "";
		display: block;
		clear: both;
	  }
	  .more {
		display: block;
		margin-bottom: 36px;
		&.active {
		  &::before, &::after {
			display: none;
		  }
		}
	  }
	}
  }
  .item-hidden {
	position: relative;
	&::before {
	  content: '';
	  position: absolute;
	  left: 45%;
	  top: -13px;
	  z-index: 20;
	  width: 0;
	  height: 0;
	  border-style: solid;
	  border-width: 0 14px 14px 14px;
	  border-color: transparent transparent #ccd3dc transparent;
	}
	&::after {
	  content: '';
	  position: absolute;
	  left: calc(45% - 2px);
	  top: -10px;
	  z-index: 30;
	  width: 0;
	  height: 0;
	  border-style: solid;
	  border-width: 0 15px 15px 15px;
	  border-color: transparent transparent #fff transparent;
	}
  }
}

/* Side popup */
.side-popup {
  position: fixed;
  right: -1px;
  top: 50%;
  z-index: 101;
  width: 432px;
  max-height: 40%;
  transition: right 0.3s;
  display: flex;
  &.side-popup-hidden {
    right: -432px;
  }
  .side-popup-header {
    position: absolute;
    transform: rotate(90deg);
    transform-origin: left top;
    padding: 0 1px 1px;
    cursor: pointer;
    background: #E2001A;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
	box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.45);
    .side-popup-header-inner {
      white-space: nowrap;
	  background: #bd160e;
      color: white;
      font-size: 15px;
      padding: 11px 18px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .side-popup-content {
    position: relative;
    overflow: auto;
    background: white;
    color: black;
    padding: 11px 18px;
    border: 2px solid #E2001A;
    box-shadow: 5px 5px 9px 0 rgba(0, 0, 0, 0.45);
    font-size: 105%;
    h1, h2, h3, h4, h5, h6 {
      color: white;
    }
    .side-popup-close {
      position: relative;
      left: 18px;
      bottom: 11px;
      float: right;
      width: 43px;
      height: 43px;
      cursor: pointer;
      &::before, &::after {
        content: "";
        display: block;
        position: absolute;
        left: calc(50% - 9px);
        top: calc(50% - 1.4px);
        width: 18px;
        height: 3px;
        background: black;
        border-radius: 1.4px;
        transform-origin: center center;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
  @media screen and (max-width: 504px) {
    display: none;
  }
}

/* Toggle Texts */
.toggle-texts {
	margin-bottom: 20px;
	.text {
		.title {
			position: relative;
			cursor: pointer;
			border-bottom: 1px solid #d5d5d5;
			padding: 8px 40px 4px 0;
			&::after {
				content: '';
				display: block;
				width: 0;
				height: 0;
				border: 6px solid transparent;
				border-left-color: #E2001A;
				position: absolute;
				right: 12px;
				top: 12px;
				transform-origin: 50% 50%;
				transition: transform 0.3s;
			}
		}
		.content {
			display: none;
			padding: 12px 0 16px;
		}
		&.open {
			.title::after {
				transform: translate(-6px, 3px) rotate(90deg);
			}
			.content {
				display: block;
			}
		}
	}
}

/* Social Buttons */
.social-buttons {
	a {
		display: inline-block;
		width: 32px;
		height: 32px;
		text-indent: -99999px;
		background-image: url(/static/svg/social.svg);
		background-size: auto 100%;
		background-repeat: no-repeat;
		transition: 0.3s opacity;
		&.facebook {
			background-position-x: 0;
		}
		&.twitter {
			background-position-x: -64px;
		}
		&.linkedin {
			background-position-x: -32px;
		}
		&.mail {
			background-position-x: -96px;
		}
		&.whatsapp {
			background-position-x: -128px;
		}
		&:hover {
			opacity: 0.7;
		}
	}
}

/* Gmap */

.gmap { width: 100%; height: 198px; }
.gmap img { max-width: inherit; }

.popup-contact { font-size: 14px; background: #fff; color: #000; width: 171px; height: 72px; padding: 9px; }
.popup-contact ul { margin: 0; }
.popup-contact ul li { padding: 0; background: none; }

/* Markers */

.marker {
  &.film-club, &.team-project {
    font-family: 'Merriweather', sans-serif !important;
    .logo { margin-bottom: 14px; }
    .name { font-size: 1.3em; color: #E2001A;}
  }
}

/* Pager */

.pager { margin: 27px 0; text-align: center; position: relative; }
.pager li { display: inline-block; padding: 0; background: none; }
.pager li a { display: block; width: 32px; height: 32px; line-height: 32px; background: #e3e3e3; text-align: center; }
.pager li a.active { background: #054a7e; color: #fff; text-decoration: none; }
.pager .prev { position: absolute; left: 0; top: 5px; }
.pager .next { position: absolute; right: 0; top: 5px; }
.pager .counter { display: none; }

/* Favorite movies */

.select-autosubmit {
  button[type=submit] {
	display: none;
  }
}
.add-favorite-movie {
  form.std label {
	display: none;
  }
}
.favorite-movies {
  .movies &.full.item-list {
	.item {
	  float: left;
	  display: flex;
	  align-items: center;
	  width: 50%;
	  margin-bottom: 15px;
	  .img {
		width: 50%;
	  }
	  .actions {
		width: 50%;
		padding-left: 15px;
	  }
	  @media screen and (max-width: 1079px) {
		width: 50%;
		&:last-child {
		  display: flex;
		}
	  }
	  @media screen and (max-width: 810px) and (min-width: 692px) {
		width: 100%;
	  }
	  @media screen and (max-width: 575px) {
		width: 100%;
	  }
	}
	&::after {
	  content: "";
	  display: block;
	  clear: left;
	}
  }
}
.logged-in ~ .favorite-movies {
  position: relative;
  display: inline-block;
  color: #E2001A;
  text-decoration: none;
  width: 24px;
  text-indent: -99999px;
  &::after {
	content: "";
	position: absolute;
	left: 0;
	width: 24px;
	height: 24px;
	text-indent: 0;
	background: url(/static/svg/hearth-red-fill.svg);
  }
  &:hover::after {
	background: url(/static/svg/hearth-red-stroke.svg);
  }
}
#lecture-add-to-favorite, #lecture-remove-from-favorite {
  padding-left: 5px;
  padding-right: 5px;
}
#lecture-add-to-favorite::before {
  content: " ";
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background: url(/static/svg/hearth-white-fill.svg);
  background-size: contain;
  margin-right: 5px;
  vertical-align: top;
}
#lecture-remove-from-favorite::before {
  content: " ";
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background: url(/static/svg/hearth-white-stroke.svg);
  background-size: contain;
  margin-right: 5px;
  vertical-align: top;
}

/* Less-more */
.item-list-less-more {
	.item.less-more-hidden {
		display: none;
	}
	.less-more-link {
		display: inline-block;
		margin-top: 25px;
		font-weight: bold;
		svg {
			position: relative;
			top: 3px;
			left: 3px;
			color: #e2001a;
		}
		&.less-link {
			svg {
				top: 0;
				transform: scaleY(-1);
				transform-origin: center;
			}
		}
	}
}

/*================================= Various Content =================================*/

.last { margin-bottom: 0 !important; }

/* Buttons */

.btn { font-family: 'Merriweather',sans-serif; font-weight: 600; font-size: 15px; line-height: 18px; background: transparent; color: #e2001a; border: 2px solid #e2001a; padding: 15px 27px 12px 27px; display: inline-block; *display: inline; zoom: 1; vertical-align: top; text-decoration: none; position: relative; margin-top: 4px; margin-bottom: 4px; }
.btn:hover { background-color: #e2001a; color: #fff; }
/*.btn:after { content: ''; position: absolute; left: 0; top: 0; z-index: -1; width: 100%; height: 100%; background: #e2001a; @include scale(0); @include transition(all 0.2s); }*/
/*.btn:hover:after { @include scale(1); }*/

.btn.alt { background: #fff; color: #aac800; }
.btn.alt:hover { /*background: #aac800;*/ color: #fff; }

/* Vertical align */

.va-wrap { display: table; width: 100%; height: 100%; }
.va-m { display: table-cell; vertical-align: middle; height: 100%; }
.va-t { display: table-cell; vertical-align: top; height: 100%; }
.va-b { display: table-cell; vertical-align: bottom; height: 100%; }

/* SVG icons */

.icon { width: 18px; height: 18px; display: inline-block; *display: inline; zoom: 1; vertical-align: top; position: relative; fill: currentColor; }
.icon-porta { width: 58px; }
.icon-play { display: inline-block; vertical-align: middle; width: 0; height: 0; position: relative; top: -2px; border-style: solid; border-width: 0.35em 0 0.35em 0.5em; border-color: transparent transparent transparent currentColor; margin-right: 14px; }

.hui-state-negative { color: #e2001a; }
.hui-state-positive { color: #090; }

/* Hidden content */

.sh-content { display: none; }

/*================================= Columns =================================*/

.col2-set, .col3-set { letter-spacing: -0.31em; *letter-spacing: normal; *word-spacing: -0.43em; }
.col { display: inline-block; *display: inline; zoom: 1; vertical-align: top; letter-spacing: normal; word-spacing: normal; }

.col-narrow { max-width: 756px }

.col2-l-set { margin: 0; }
.col2-l-set .col-1 { float: left; max-width: 324px; width: 100%; }
.col2-l-set .col-2 { padding-left: 387px; }

.col2-r-set { margin: 0; }
.col2-r-set .col-1 { padding-right: 387px; }
.col2-r-set .col-2 { float: left; max-width: 324px; width: 100%; }

.col2-set { margin: 0 0 27px -18px; }
.col2-set .col { width: 50%; padding: 0 0 0 18px; }

.col3-set { margin: 0 0 27px -18px; }
.col3-set .col { width: 33.33333%; padding: 0 0 0 18px; }

.col4-set { margin: 0 0 27px -18px; }
.col4-set .col { width: 25%; padding: 0 0 0 18px; }

.ib { font-family: 'Arial',sans-serif; letter-spacing: -0.31em; *letter-spacing: normal; *word-spacing: -0.43em; }
.ib > * { font-family: 'Merriweather',sans-serif; display: inline-block; *display: inline; zoom: 1; vertical-align: top; letter-spacing: normal; word-spacing: normal; }

/************************************************************************************
FOOTER
*************************************************************************************/

.footer { font-size: 15px; background: #054a7e; color: #8ed8ff; padding: 45px 0; }
.footer h3 { font-size: 15px; line-height: 1.625em; color: #fff; }
.footer ul li { font-size: 15px; line-height: 1.625em; font-weight: 600; margin: 0; padding: 0; }
.footer a { color: #8ed8ff; }
.footer a:hover { color: #fff; }
.footer .copy { color: rgba(255,255,255,0.5); margin: 0; padding-left: 100px; }
.footer .copy img { position: absolute; width: 80px; left: 14px; top: -6px; }

.footer .social { text-align: right; }
.footer .social a { display: inline-block; vertical-align: top; padding-left: 9px; }
.footer .social .icon { width: 40px; height: 40px; }

.porta { width: 62px; height: 14px; display: block; float: right; color: rgba(255,255,255,0.5); }
.porta:hover { color: #ff4942; }

/************************************************************************************
ENTRY
*************************************************************************************/

.post-entry { margin-bottom: 36px; }

.entry { margin-bottom: 36px; position: relative; }
.entry h2 { margin-top: 27px; }
.entry h3 { margin-top: 27px; }
.entry h2:first-child { margin-top: 0; }
.entry h3:first-child { margin-top: 0; }
.entry ul { overflow: hidden; }
.entry ul:not(.unstyled) li { position: relative; padding-left: 27px; }
.entry ul:not(.unstyled) li:before { content: ''; position: absolute; left: 3px; top: 8px; width: 7px; height: 7px; display: block; background: #e2001a; }
.entry ol { overflow: hidden; }
.entry ol li { margin: 0; }
.entry ol li:before { color: #e2001a; }

.entry .img-main { margin-bottom: 27px; }

.print { text-align: right; }
.print .icon { width: 21px; height: 21px; color: #e2001a; margin-right: 9px; }

.text-big { font-size: 15px; line-height: 1.5em; margin-bottom: 27px; }

.note-hint { padding: 27px; margin-bottom: 18px; background: #eaedf1; }
.note-hint .head { font-size: 15px; font-weight: 600; margin-bottom: 5px; }
.note-hint p { margin: 0; }

/************************************************************************************
TABLES
*************************************************************************************/

.entry table { width: 100%; margin: 0 0 27px 0; border-top: 2px solid #e0e5ea; }
.entry table thead th:first-child { padding-left: 0; }
.entry table thead th { border: none; border-bottom: 2px solid #809db8; }
.entry table td { border-bottom: 2px solid #e0e5ea; padding: 13px 18px; }
.entry table td:first-child { padding-left: 0; }

.entry table th { font-size: 15px; line-height: 18px; font-weight: 600; padding: 14px 18px; background: none; color: #000; text-align: left; border-right: 2px solid #809db8; border-bottom: 2px solid #e0e5ea; }
.entry table p { margin: 0; }

table.side-nav-table { margin-top: 18px; }
table.side-nav-table td { vertical-align: middle; padding: 11px 18px 7px 18px; }
table.side-nav-table td:first-child { padding-left: 20px; padding-right: 0; }
table.side-nav-table td i.fa { color: #e2001a; }

table.activity { width: 100%; }
table.activity tr { margin: 0; }
table.activity td.title { font-size: 16px; font-weight: 600; background: #054a7e; color: #fff; padding: 9px 27px; border-bottom: 2px solid #fff; }
table.activity td.title.alt { background: #e2001a; border-bottom: 27px solid #fff; }
table.activity td.title.ttu { text-transform: uppercase; }
table.activity td.head { font-weight: 600; color: #054a7e; padding: 27px; border-bottom: 2px solid #8ed8ff; border-right: 2px solid #8ed8ff; text-align: right; }
table.activity td.desc { padding: 27px; border-bottom: 2px solid #8ed8ff; color: #000; }
table.activity tr:last-child td.head { border-bottom: none; }
table.activity tr:last-child td.desc { border-bottom: none; }
table.activity td p { margin-bottom: 0; }
table.activity tfoot td { text-align: center; padding-top: 27px; }
table.activity td b, table.activity td strong { color: #e2001a; }
table.activity ul li { position: relative; padding-left: 27px; }
table.activity ul li:before { content: ''; position: absolute; left: 3px; top: 8px; width: 7px; height: 7px; display: block; background: #e2001a; }

/* Screening*/

.screenings table { font-size: 13px; width: 100%; margin: 0; border: none; }
.screenings table thead tr td { font-size: 16px; padding: 9px 0; background: #e2001a; color: #fff; }
.screenings table tr td.divider { border-right: 4px solid #999; }
.screenings table tr td { border: none; background: #fff; }
.screenings table tr td .pd { padding: 9px; }
.screenings table tr td a { font-size: 13px; color: #fff; background: none; text-decoration: none; display: block; height: 100%; position: relative; }
.screenings table tr td a:hover .title { text-decoration: none; }
.screenings table tr:nth-child(even) td { background: #f7f9fb; }
.screenings table tr:last-child td { border-bottom: 0; }
.screenings table tr td.scr { min-width: 180px; padding: 0; opacity: 0.7; }
.screenings table tr td.scr:hover { opacity: 1; }
.screenings table tr td.scr:not(.disabled) a:hover:after { content: ''; position: absolute; left: 0; top: 0; z-index: 10; width: 100%; height: 100%; background: rgba(255,255,255,0.8); }
.screenings table tr td.scr:not(.disabled) a:hover .btn-wrapper { display: block; }
.screenings table tr td.scr:not(.disabled) a:hover .status {  }
.screenings table tr td.scr a { padding: 9px; }
.screenings table tr td.scr a .btn-wrapper { position: absolute; z-index: 20; left: 0; width: 100%; top: 50%; padding: 0 9px; height: 27px; margin-top: -14px; text-align: center; display: none; }
.screenings table tr td.scr a .btn { font-size: 12px; line-height: 18px; font-style: normal; display: inline-block; vertical-align: top; width: 100%; padding: 5px 5px; text-align: center; cursor: pointer; }
.screenings table tr td.place { width: 54px; background: #054a7e; color: #fff; }
.screenings table tr td.place .theatre { font-size: 11px; line-height: 1.4em; text-transform: uppercase; font-weight: 600; display: block; }
.screenings table tr td.place .time { color: rgba(255,255,255,0.5) }
.screenings table tr td.disabled { opacity: 0.5; background: #e3e3e3 !important; }
.screenings table tr td.disabled a { color: rgba(0,0,0,0.8); cursor: default; }
.screenings table tr td.disabled a .type { border-bottom: 1px dashed rgba(0,0,0,0.5); }

.screenings table tr td a .title { font-size: 13px; line-height: 1.5em; text-decoration: underline; padding-bottom: 10px; display: block; }
.screenings table tr td a .free { font-size: 11px; line-height: 1.5em; display: block; }
.screenings table tr td a .type { font-size: 11px; line-height: 1.5em; padding-bottom: 4px; margin-bottom: 4px; display: block; border-bottom: 1px dashed rgba(255,255,255,0.5); }
.screenings table tr td a .moderator { font-size: 9px; line-height: 1.5em; font-style: italic; padding-top: 5px; display: block; }
.screenings table tr td a .host { font-size: 9px; line-height: 1.5em; font-style: italic; padding-top: 5px; display: block; }
.screenings table tr td a .status { display: inline-block; vertical-align: top; margin-right: 5px; position: absolute; right: 9px; bottom: 3px; cursor: help; z-index: 50; }

.screenings table tr td .day { font-size: 13px; line-height: 1.4em; text-transform: uppercase; font-weight: 600; display: block; }
.screenings table tr td .date { color: rgba(255,255,255,0.7); }

.screenings table tr td.c-f5f53d a { color: rgba(0,0,0,0.8); }
.screenings table tr td.c-f5f53d a .type { border-bottom: 1px dashed rgba(0,0,0,0.5); }
.screenings table tr td.c-e3e3e3 a { color: rgba(0,0,0,0.8); }
.screenings table tr td.c-e3e3e3 a .type { border-bottom: 1px dashed rgba(0,0,0,0.5); }

/* Fixed header table */

.fht-table,
.fht-table thead,
.fht-table tfoot,
.fht-table tbody,
.fht-table tr,
.fht-table th,
.fht-table td { margin: 0; padding: 0; font-size: 100%; font: inherit; vertical-align: top; }
.fht-table { border-collapse: collapse; border-spacing: 0; }
.fht-table-wrapper,
.fht-table-wrapper .fht-thead,
.fht-table-wrapper .fht-tfoot,
.fht-table-wrapper .fht-fixed-column .fht-tbody,
.fht-table-wrapper .fht-fixed-body .fht-tbody,
.fht-table-wrapper .fht-tbody { overflow: hidden; position: relative;}
.fht-table-wrapper .fht-fixed-body .fht-tbody,
.fht-table-wrapper .fht-tbody { overflow: auto; }
.fht-table-wrapper .fht-table .fht-cell { overflow: hidden; height: 1px; }
.fht-table-wrapper .fht-fixed-column,
.fht-table-wrapper .fht-fixed-body { top: 0; left: 0; position: absolute; }
.fht-table-wrapper .fht-fixed-column { z-index: 1; }

/* Scrollbar on top hack */

.fht-thead { position: relative; z-index: 500; }
.fht-tbody { height: auto !important; }

.fht-fixed-body .fht-thead { overflow-x: scroll !important;  }
.fht-fixed-body .fht-thead table td:last-child { padding-right: 0 !important; }
.fht-fixed-body .fht-tbody { overflow-x: hidden !important; }
.fht-fixed-body .fht-tbody tbody tr:first-child td { padding-top: 18px; }
/*
::-webkit-scrollbar { width: 6px; }
::-webkit-scrollbar-thumb { border-radius: 10px; background: #f8c301; }
*/
/************************************************************************************
FORMS
*************************************************************************************/

form.std label { font: normal 15px/22px 'Merriweather',sans-serif; color: #054a7e; margin: 0 0 9px 0; display: block; }
form.std label.header-like { color: #000; font-size: 1em; font-weight: 600; }
form.std input.text { font: normal 15px/22px 'Merriweather',sans-serif; color: #054a7e; border: 2px solid #afc5d6; padding: 7px 14px 6px 14px; height: 41px; width: 100%; }
form.std textarea { font: normal 15px/22px 'Merriweather',sans-serif; color: #054a7e; resize: vertical; border: 2px solid #afc5d6; padding: 7px 14px; width: 100%; min-width: 100%; max-width: 100%; height: 135px; min-height: 135px; max-height: 270px; }
form.std select { font: normal 15px/22px 'Merriweather',sans-serif; color: #054a7e; width: 100%; padding: 0 9px; border: 2px solid #afc5d6; padding: 7px 14px 6px 14px; }
form.std option { font: normal 15px/18px 'Merriweather',sans-serif; padding: 2px 18px; }
form.std option:before { content: none !important; }
form.std .note { font-size: 12px; font-style: italic; line-height: 1.5em; padding-top: 9px; margin-bottom: 0; color: #666; }
form.std .req { color: #e2001a; }
form.std .hint { font-size: 9px; width: 18px; height: 18px; line-height: 18px; text-align: center; text-decoration: none; color: #fff; background: #e2001a; display: inline-block; vertical-align: top; position: absolute; right: 0; top: 0; @include border-radius(50%); }
form.std .entry { font: normal 15px/22px 'Merriweather',sans-serif; }

form.std input.text[required="required"] { /*background-image: url('../img/req.png');*/ background-position: right center; background-repeat: no-repeat; }

form.form-custom {
    .multiselect{
        margin-bottom: 18px;
        .cell{
            margin-bottom: 18px;
        }
        .label-cell{
            margin-bottom: 9px;
        }
    }
	.disabled { color: #e2001a; }
	h2 + p.disabled { padding-top: 8px; }
	.cell-set + p.disabled { padding-bottom: 28px; }
	.cell.radio-depended {
		padding-left: 57px;
	}
   	label *[title], .contact-form-slots *[title] {
		border-bottom: 1px dotted black;
	    position: relative;
		.tooltip-container {
			display: none;
			position: absolute;
			top: 100%;
			padding-top: 7px;
			z-index: 999999;
		  	margin-right: -540px;
			.tooltip {
				display: block;
				border: 2px solid #e2001a;
				background: white;
			  	color: black;
				padding: 4px 7px;
				z-index: 2;
				margin-top: -7px;
				max-width: 600px;
			}
		}
		.tooltip-container::before {
			content: "";
			display: block;
			position: relative;
			width: 13px;
			height: 13px;
			border: 2px solid #e2001a;
			border-right: none;
			border-bottom: none;
			background: white;
			transform: rotate(45deg);
			transform-origin: center center;
			margin: 0 auto;
		}
   	}
}
.seminar-regform{
	.clearfix{
		overflow:hidden;
	}
	form.std{
		margin-top: 18px;
		textarea {
			height: 65px;
			min-height: 65px;
		}
	}
}

.seminar-calendar-actions {
	margin-bottom: 40px;
	font-weight: bold;
	* {
		vertical-align: middle;
	}
	ul, li, a {
		display: inline-block;
		margin: 0;
		padding: 0;
	}
	ul {
		margin-left: 10px;
	}
	a {
		width: 32px;
		height: 32px;
		background: url('/static/svg/calendar.svg');
		background-size: auto 100%;
		overflow: hidden;
		text-indent: -99999px;
		margin: 0 5px;
		&.google {
			background-position-x: 0;
		}
		&.outlook {
			background-position-x: -32px;
		}
		&.office365 {
			background-position-x: -64px;
		}
		&.yahoo {
			background-position-x: -96px;
		}
	}
}

#seminar-detail {
	.img {
		float: left;
		width: 252px;
		position: relative;
		.label {
			position: absolute;
			z-index: 10;
			right: 13px;
			bottom: 13px;
			width: 56px;
			height: 56px;
			border-radius: 50%;
			background: black;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			img {
				max-width: 48px;
				max-height: 48px;
			}
		}
	}
}

#registration-popup {
	width: 800px;
	max-width: 100%;
	padding: 20px;
	background: white;
	.content {
		.aside {
			margin-bottom: 32px;
			h2 {
				color: black;
				font-size: 200%;
			}
			.img {
				width: 100%;
				img {
					width: 100%;
				}
			}
		}
		.entry {
			padding-top: 40px;
		}
	}
	.actions {
		text-align: center;
		a.btn.primary {
			background: #e2001a;
			color: white;
			&:hover {
				background: white;
				color: #e2001a;
			}
		}
		a {
			display: inline-block;
			margin: 0 32px;
			vertical-align: middle;
		}
	}
	@media screen and (min-width: 692px) {
		.content {
			.aside {
				float: left;
				width: 40%;
				margin-right: 16px;
				margin-top: 16px;
			}
			&:after {
				content: '';
				display: block;
				clear: both;
			}
		}
	}
}

::-webkit-input-placeholder { color: rgba(5,74,126,0.6); }
:-moz-placeholder { color: rgba(5,74,126,0.6); }
::-moz-placeholder { color: rgba(5,74,126,0.6); }
:-ms-input-placeholder { color: rgba(5,74,126,0.6); }

/*================================= Form standard =================================*/

/* Standard */

form.std .cell-set { margin: 0 0 0 -18px; }
form.std .cell { margin: 0 0 27px 0; padding-left: 18px; position: relative; text-align: left; }
form.std .cell.full { width: 100%; }
form.std .cell.inline { display: inline-block; }

/* Inline */

form.std .set-2 .cell { width: 50%; }
form.std .set-3 .cell { width: 33.33333%; }
form.std .set-4 .cell { width: 25%; }
form.std .set-5 .cell { width: 20%; }
form.std .set-6 .cell { width: 16.66666%; }

/* Checkbox & radio wrapper */

form.std .toggle { position: relative; padding: 0 0 0 27px; }
form.std .toggle .error-placement-wrapper { margin-left: -27px; }
form.std .toggle label { font-weight: 600; display: inline-block; *display: inline; zoom: 1; vertical-align: top; margin: 0 0 0 0; }
form.std .toggle .note { font-size: 15px; max-width: 100%; }
form.std .inline-checkboxes { display: inline-block; }
form.std .inline-checkboxes .toggle { display: inline-block; padding-right: 20px; padding-bottom: 7px; }

/* Various */

form.std .radio,
form.std .checker { position: absolute; left: 0; top: 1px; }

/* Form filter */

form.form-filter .cell-set { margin-left: 0; width: 100%; border-top: 2px solid #afc5d6; display: flex; flex-wrap: wrap; justify-content: center; }
form.form-filter .cell { border: 2px solid #afc5d6; border-left: none; border-top: none; padding: 0; height: 45px; margin: 0; }
form.form-filter .cell .selector { border: none; height: 43px; }
form.form-filter .cell .selector span { border: none; height: 43px; line-height: 43px; }
form.form-filter .cell select { border: none; height: 43px; }
form.form-filter .cell input.text { border: none; height: 41px; }
form.form-filter .btn { font-size: 15px; line-height: 18px; position: absolute; right: 14px; top: 14px; width: 117px; height: 45px; padding: 5px 9px; }

form.std.form-filter .select2-container { position: absolute; top: 0; background: white; border: none; padding: 0; height: 43px; width: 100% !important; }
form.std.form-filter .select2-container span { background: none; }
form.std.form-filter .select2-container--default .select2-selection--single .select2-selection__arrow { background-color: transparent; }
form.std.form-filter .selector:hover .select2-container--default .select2-selection--single .select2-selection__arrow { background-position: right -30px; }
form.std.form-filter .select2-container--default .select2-selection--single .select2-selection__rendered { padding: 9px 15px 7px 15px; color: #054a7e; background: none; }
.select2-container { cursor: pointer; }
.select2-expandable-optgroup::before { content: ""; display: block; width: 7px; height: 7px; margin: 13px 11px 0 0; float: right; border-top: 1px solid #e2001a; border-right: 1px solid #e2001a; transform: rotate(45deg); }
.select2-expandable-optgroup .select2-lecture-filter-item { display: block; margin-top: -5px; margin-bottom: -5px; color: #054a7e; position: relative; padding-left: 7px; }
.select2-expandable-optgroup .select2-lecture-filter-item::before { content: ""; display: block; width: 6px; height: 6px; border-radius: 3px; background: #e2001a; position: absolute; left: -6px; top: 7px; }
.select2-expandable-optgroup li:first-child .select2-lecture-filter-item:not(.select2-lecture-tip-filter-item) { font-weight: bold; }
.select2-results__option--highlighted .select2-lecture-filter-item { color: white; }
.select2-container--default .select2-expandable-optgroup .select2-results__group { cursor: pointer; }
.select2-container--default .select2-selection--single { border-radius: 0 !important; }
.select2-expandable-optgroup:hover { background: #afc5d6; }
.select2-expandable-optgroup .select2-results__options { background: white; }
.select2-expandable-sub-selected { background: #dddddd; }
.select2-expandable-mouseover { background: #afc5d6; }
.select2-expandable-mouseover .select2-results__options, .select2-expandable-opened-by-mouse .select2-expandable-optgroup .select2-results__options { position: absolute; top: 0; left: 100%; border: 1px solid #859bac; width: 100%; min-height: 100%; overflow-y: auto; }
.select2-expandable-mouseover .select2-results__options { z-index: 2; }
.select2-expandable-opened-by-mouse .select2-expandable-mouseover .select2-results__options { z-index: 1; }
.select2-expandable-mouseover.select2-expandable-optgroup .select2-lecture-filter-item, .select2-expandable-opened-by-mouse .select2-expandable-optgroup .select2-lecture-filter-item { padding-left: 0; }
.select2-expandable-mouseover.select2-expandable-optgroup .select2-lecture-filter-item::before, .select2-expandable-opened-by-mouse .select2-expandable-optgroup .select2-lecture-filter-item::before { display: none; }
.col2-l-set .col-2 form.form-filter .btn, .layout-content form.form-filter .btn { top: 0; width: 162px; }

#lecturefilterform .cell:nth-child(1) { width: 21%; border-left: 2px solid #afc5d6; }
#lecturefilterform .cell:nth-child(2) { width: 34%; }
#lecturefilterform .cell:nth-child(3), #lecturefilterform .cell:nth-child(4) { width: 10.5%; }
#lecturefilterform .cell:nth-child(5), #lecturefilterform .cell:nth-child(6) { width: 12%; }
@media screen and (max-width: 1214px) {
  #lecturefilterform .cell:nth-child(1), #lecturefilterform .cell:nth-child(2) { width: 50%; }
  #lecturefilterform .cell:nth-child(3) { border-left: 2px solid #afc5d6; }
  #lecturefilterform .cell:nth-child(3), #lecturefilterform .cell:nth-child(4), #lecturefilterform .cell:nth-child(5), #lecturefilterform .cell:nth-child(6) { width: 25%; }
}

#lecturefilterform .cell:nth-child(7), #lecturefilterform .cell:nth-child(8), #lecturefilterform .cell:nth-child(9), #lecturefilterform .cell:nth-child(10), #lecturefilterform .cell:nth-child(11) { border: none; width: auto; max-width: 50%; padding: 13px 0 0 25px; }

.select2-container.select2-container--default .select2-results > .select2-results__options { max-height: 486px; }
.select2-container .select2-dropdown { border: 1px solid #859bac; border-radius: 0; }
.select2-container.select2-container--open .select2-dropdown--below { border-top: 1px solid #859bac; }
.select2-container.select2-container--open .select2-dropdown--above { border-bottom: 1px solid #859bac; }

#lecture-search-reset-filter {
  position: absolute;
  top: 72px;
  right: 32px;
  font-weight: bold;
  &.hidden {
	display: none;
  }
  @media screen and (max-width: 810px) {
	position: static;
	display: block;
	text-align: center;
	padding: 9px 0;
  }
}

#lecturefilterform {
  text-align: center;
  .fields {
	text-align: left;
  }
  .filtered-input {
	&:not(select), & label, & + .select2 .select2-selection__rendered {
	  color: #e2001a;
	}
  }
	.btn {
		margin-top: 0;
		height: 47px;
		padding-top: 6px;
	}
}
@media screen and (max-width: 990px) {
  #lecturefilterform {
	.head {
	  display: none;
	}
	.fields {
	  padding-left: 0;
	}
  }
}
@media screen and (min-width: 691px) and (max-width: 810px) {
  #lecturefilterform {
	#send {
	  position: static;
	  margin-top: 15px;
	}
	.fields {
	  padding-right: 0;
	}
  }
}
#schoolfilterform .btn {
	margin-top: 0;
	height: 41px;
	padding-top: 10px;
}

/* Form cart */

form.form-cart input.text { padding: 5px 9px; }

/* Form search */

form.form-search { max-width: 180px; position: relative; top: -7px; }
form.form-search input.text { font-size: 15px; border: 1px solid #afc5d6; margin: 1px; height: 34px; pading-top: 6px; padding-bottom: 4px; }
form.form-search input.text:focus { outline: 1px solid #afc5d6; }
form.form-search .btn { position: absolute; right: 0; top: 0; height: 100%; width: 45px; border: none; padding: 0; text-align: center; }
form.form-search .btn:hover { background: none; }
form.form-search .btn .icon { vertical-align: top; }

form.std .input-btn { padding-right: 171px; position: relative; }
form.std .input-btn .cell { margin: 0; width: 100%; padding: 0; }
form.std .input-btn .btn { width: 162px; position: absolute; right: 0; top: 30px; height: 41px; padding: 11px 0; text-align: center; }
form.std .select2-container { font: normal 15px/22px 'Merriweather',sans-serif; color: #054a7e; background: #fff; border: 2px solid #afc5d6; padding: 7px 14px 6px 14px; height: 41px; width: 100%; }
form.std .select2-container--default .select2-selection--single { border: none; background: none; }
form.std .select2-container--default .select2-selection--single .select2-selection__arrow { background: #fff url('../img/select.png') right 18px no-repeat; width: 27px; height: 100%; right: 0; top: 0; }
form.std .select2-container--default .select2-selection--single .select2-selection__arrow b { display: none; }

/* Form custom */

form.form-custom .cell-set.set-2 .cell { width: 100%; }
form.form-custom input.text { max-width: 414px; }

/* Form newsletter */

form.form-nl { max-width: 450px; width: 100%; margin-bottom: 36px; }
form.form-nl .btn { position: absolute; right: 0; top: 0; width: 135px; padding: 10px 27px 9px 27px; }
form.form-nl .cell-set { position: relative; }
form.form-nl .cell { padding-right: 144px; position: static; }

select#schoolLevels, select#schoolSubjects { height: 126px; }
select#schoolLevels option, select#schoolSubjects option { padding: 2px 5px; }

/* Form login */

form.form-login { margin-bottom: 54px; }

/* Application form */

.application-form-datetime { display: inline-block; vertical-align: top; padding-right: 22px; }

/* Contact form */

.contact-form-captcha {
  label {
	float: left;
  }
  input {
	width: 90px !important;
	@media (min-width: 504px) {
	  float: left;
	  margin-left: 15px;
	  position: relative;
	  top: -9px;
	}
  }
  &::after {
	content: "";
	display: block;
	clear: both;
  }
}

form.std {
	.contact-form-slots {
		td {
			border: 2px solid #e0e5ea;
			padding: 6px;
			font-size: 13px;
			label {
				font-size: 13px;
			}
			.cell {
				margin: 0;
				padding: 0;
				.disabled label {
					opacity: 0.5;
				}
				div.radio span {
					background-position-x: -8px !important;
				}
			}
		}
	}
}

/************************************************************************************
FLASH MESSAGES
*************************************************************************************/

.flash-message { font: normal 15px/22px 'Merriweather',sans-serif; background: #fc796b; color: #fff; width: 100%; padding: 14px 45px 14px 18px; margin: 0 0 18px 0; display: inline-block; *display: inline; *zoom: 1; vertical-align: top; position: relative; border-radius: 0 0 4px 4px; -moz-border-radius: 0 0 4px 4px; -webkit-border-radius: 0 0 4px 4px; }
.flash-message .close { font: normal 32px/22px 'Merriweather',sans-serif; width: 18px; height: 18px; padding: 0; line-height: 16px; text-align: center; color: #c96055; border: none; display: block; position: absolute; right: 15px; top: 14px; background: none; }
.flash-message .close:hover { color: #fff !important; }

.flash-message.alert-danger { background: #d14233; color: #fff; }
.flash-message.alert-danger .close { color: #912519; }

.flash-message.alert-success { background: #19b698; color: #fff; }
.flash-message.alert-success .close { color: #13927a; }

.modal-content .flash-message { margin: 0 0 18px 0; }

.error, form.std label.error { font-size: 15px; font-weight: 400; color: #ff0030; display: block; margin-top: 5px; }

#flash-messages { position: fixed; top: 0; left: 0; width: 100%; z-index: 9999; }
#flash-messages .flash-message { margin: 0 auto; width: 1062px; max-width: 100%; display: block; }

/************************************************************************************
TABS
*************************************************************************************/

.tabs { position: relative; }

.tabs .tab-nav ul { margin: 0 0 0 -1px; padding: 0; }
.tabs .tab-nav li { float: left; list-style-type: none; padding: 0; background: none; margin: 0 0 0 1px; }
.tabs .tab-nav li a { display: block; text-decoration: none; outline: 0; padding: 14px 27px; background: #000; color: #fff; }
.tabs .tab-nav li a:hover {  background: #a0a0a0; color: #000; }
.tabs .tab-nav li a.selected { background: #e3e3e3; color: #000; }

.tabs .tab-content .tab { float: left; width: 100%; padding: 0; display: none; }
.tabs .tab-content .tab.selected { position: static !important; display: block; }

/* Tabs simple */

.tabs .tab-nav-simple { text-align: center; margin-bottom: 27px; }
.tabs .tab-nav-simple .head { font-weight: 600; display: inline-block; vertical-align: top; margin: 0 9px 0 0; }
.tabs .tab-nav-simple ul { text-align: center; display: inline-block; vertical-align: top; margin: 0 0 0 -18px; }
.tabs .tab-nav-simple li { padding-left: 18px; position: relative; }
.tabs .tab-nav-simple li a { font-size: 15px; font-weight: 600; color: #054a7e; padding: 0; background: none; position: relative; }
.tabs .tab-nav-simple li a:hover { background: none; color: #e2001a; }
.tabs .tab-nav-simple li a.selected { background: none; color: #e2001a; }
.tabs .tab-nav-simple li a.selected:after { content: ''; width: 0; height: 0; border-style: solid; border-width: 0 9px 9px 9px; border-color: transparent transparent #ffffff transparent; position: absolute; left: 50%; bottom: -27px; margin-left: -9px; }
.tabs .tab-nav-simple li:after { content: '/ '; color: #afc5d6; position: absolute; left: 6px; top: 0; }
.tabs .tab-nav-simple li:first-child:after { content: none; }

/* Lecture tabs */

.tabs .tab-nav-lectures {
  text-align: center;
  margin-bottom: 22px;
  li {
	float: none;
	display: inline-block;
	margin: 0 16px 1px;
	a {
	  font-weight: bold;
	  border: 2px solid #e2001a;
	  background: transparent;
	  color: #e2001a;
	  padding: 11px 25px;
	  &:hover, &.selected {
		background: #e2001a;
		color: white;
	  }
	}
  }
}

/* Lecture tip filter */

.lecture-tip-filter {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 22px;
  .icon-tip {
	width: 36px;
	height: 36px;
	stroke: #e2001a;
	stroke-width: 1.5;
	fill-opacity: 0;
  }
  label {
	display: inline-block;
	margin: 0 6px;
	&::after {
	  content: ":";
	}
  }
  .cell {
	display: inline-block;
	vertical-align: -70%;
	margin: 0 7px;
	label, dt {
	  display: none;
	}
	select {
	  background: #fff url("../img/select.png") right 20px no-repeat;
	  border: 2px solid #afc5d6;
	  height: 41px;
	  padding: 0 0 0 14px;
	  font-size: 15px;
	  color: #054a7e;
	  font-family: 'Merriweather', sans-serif;
	  width: 252px;
	  appearance: none;
	  vertical-align: 85%;
	}
	.selector {
	  width: 252px !important;
	  display: inline-block;
	  option {
		font: normal 15px/18px 'Merriweather',sans-serif;
	  }
	}
	#subjectlevel-element {
	  position: relative;
	  .select2-container {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		span {
		  background: none;
		}
		&.select2-container--default .select2-selection--single {
		  height: 41px;
		  border: 2px solid #afc5d6;
		  background: white;
		  .select2-selection__arrow {
			width: 41px;
			height: 100%;
			background: transparent url("../img/select.png") right 18px no-repeat;
			b {
			  display: none;
			}
		  }
		  .select2-selection__rendered {
			padding: 7px 0 0 15px;
			color: #054a7e;
			font-size: 15px;
			text-align: left;
			background: none;
		  }
		}
	  }
	  &:hover .select2-container--default .select2-selection--single .select2-selection__arrow {
		background-position: right -30px;
	  }
	}
  }
}

/* Lecture tips */
#lecture-tips-content {
  h2 {
	margin-top: 28px;
	margin-bottom: 32px;
  }
  .lecture-tip-button {
	display: block;
	border: 2px solid #e2001a;
	color: #e2001a;
	text-decoration: none;
	padding: 7px;
	margin-bottom: 22px;
	text-align: center;
	&:hover {
	  background: #e2001a;
	  color: white;
	}
  }
  .lecture-tip {
	display: block;
	text-decoration: none;
	font-weight: normal;
	color: black;
	height: 110px;
	margin-bottom: 15px;
	border: 2px solid #afc5d6;
	background: white;
	overflow: hidden;
	.lecture-tip-inner {
	  padding: 9px 15px 9px 198px;
	  position: relative;
	  > img {
		max-width: 180px;
		max-height: 108px;
		position: absolute;
		left: 0;
		top: 0;
	  }
	  > p {
		margin: 2px 0;
	  }
	  .lecture-tip-introduction {
		font-weight: bold;
		font-size: 95%;
		@media screen and (max-width: 691px) {
		  font-size: 90%;
		  line-height: 1.3;
		}
		@media screen and (max-width: 576px) {
		  font-size: 85%;
		}
		@media screen and (max-width: 486px) {
		  display: none;
		}
	  }
	  h3, .lecture-tip-additional {
		display: inline;
	  }
	  h3 {
		color: #054a7e;
	  }
	  .lecture-tip-additional {
		color: #e2001a;
		font-size: 115%;
		&::before {
		  content: "/ ";
		}
	  }
	  .lecture-tip-themes {
		color: #054a7e;
		font-size: 90%;
	  }
	  .lecture-tip-description {
		margin-top: 4px;
	  }
	  .lecture-tip-additional, .lecture-tip-themes, .lecture-tip-description {
		opacity: 0;
		transition: opacity 0.3s;
	  }
	}
	&:hover {
	  background: #f5f8fa;
	  .lecture-tip-introduction, .lecture-tip-additional, .lecture-tip-themes, .lecture-tip-description {
		opacity: 1;
	  }
	}
  }
}

/* Lecture search content */

#lecture-themes-content, #lecture-search-content {
  .block.alt-2 + .block {
	padding-top: 32px;
  }
}
#lecture-themes-content {
  .container {
	max-width: 1505px;
	.movies .item-list .item {
	  @media screen and (min-width: 1520px) {
		width: 20%;
	  }
	}
  }
  @media screen and (max-width: 586px) {
	.movies .head a {
	  position: static;
	}
  }
}
#lecture-search-content {
  h2 {
	margin-bottom: 18px;
  }
  .lecture-search-results h2 {
	margin-top: 18px;
	margin-bottom: 0;
  }
  #lecture-search-title-row, .block.alpha {
	.container {
	  max-width: 1540px;
	}
  }
}

/* Slash list */

.slash-list { margin: 27px 0 9px 27px; max-width: 945px; margin-left: auto; margin-right: auto; text-align: center; }
.slash-list ul { margin: 0; display: inline-block; vertical-align: top; }
.slash-list ul li { display: inline-block; vertical-align: top; position: relative; padding: 0 9px 0 0; }
.slash-list ul li:after { content: '/ '; color: #afc5d6; padding-left: 9px; }
.slash-list ul li:last-child:after { content: none; }
.slash-list ul li.head { display: inline-block; vertical-align: top; position: relative; padding: 0; }
.slash-list ul li.head:after { content: none; }
.slash-list ul li.active a { color: #e2001a; font-weight: 600; text-decoration: none; cursor: default; }
.slash-list ul li.active { color: #e2001a; font-weight: 600; text-decoration: none; }
.slash-list ul li .num { color: #252525; }

.slash-list.alt ul li a { font-weight: 600; }

/* Slash list for lectures */
.slash-list-lectures {
  max-width: none;
  margin: 11px 0 27px 0;
  ul {
	li {
	  margin: 7px 3px;
	  &:after {
		display: none;
	  }
	  a {
		display: inline-block;
		color: #054a7e;
		font-weight: bold;
		text-decoration: none;
		border: 1px solid #054a7e;
		padding: 4px 7px;
		&:hover {
		  border-color: #e2001a;
		  background: #e2001a;
		  color: white;
		}
	  }
	}
  }
  @media screen and (max-width: 828px) {
	ul {
	  li {
		margin: 0;
		padding: 0;
		a {
		  border-width: 0;
		  padding: 0;
		  margin: 1px 6px;
		  font-weight: normal;
		  &:hover {
			background: none;
			text-decoration: underline;
			color: #054a7e;
		  }
		}
	  }
	}
  }
}

/* Lecture search */

#lecture-search-sorting {
  text-align: right;
  label {
	vertical-align: middle;
	&::after {
	  content: ":";
	  position: relative;
	  left: -4px;
	}
  }
  dt {
	display: none;
  }
  dd {
	display: inline-block;
	width: 180px;
	vertical-align: middle;
	.selector {
	  height: 28px;
	  line-height: 28px;
	  span {
		height: 25px;
		line-height: 25px;
		background-position-y: 9px;
	  }
	  select {
		height: 25px;
		line-height: 25px;
		option {
		  font: normal 15px/18px 'Merriweather',sans-serif;
		}
	  }
	}
  }
}

.lecture-search-results-items, .theme-lecture-list, .movies-related {
  margin: 0 auto;
  width: 1510px;
  @media screen and (max-width: 1559px) {
	width: 1208px;
  }
  @media screen and (max-width: 1257px) {
	width: 906px;
  }
  @media screen and (max-width: 948px) {
	width: 604px;
  }
  @media screen and (max-width: 638px) {
	width: 300px;
  }
  .layout-content & {
	width: auto !important;
  }
  .lecture-search-results-item {
	width: 300px;
	height: 247px;
	margin: 0 1px;
	display: inline-block;
	position: relative;
	vertical-align: top;
	.lecture-search-results-item-inner {
	  display: block;
	  text-align: left;
	  background: #054a7e;
	  color: white;
	  text-decoration: none;
	  position: absolute;
	  left: 0;
	  top: 0;
	  width: 100%;
	  box-shadow: 0 0 0 rgba(255, 255, 255, 0.5);
	  transition: width 0.3s, left 0.3s, top 0.3s, padding 0.3s, box-shadow 0.3s;
	  img {
		height: 191px;
	  }
	  h3 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: white;
		padding: 11px 11px 7px;
		margin: 0;
		transition: padding 0.3s;
		font-size: 15px;
		line-height: 25px;
		~ * {
		  display: none;
		}
	  }
	  p {
		padding: 2px 0;
		margin: 0;
	  }
	  .theme {
		color: #7BC8EA;
		font-weight: bold;
		font-size: 90%;
	  }
	  .subtitle {
		color: #afc5d6;
		font-weight: bold;
		font-size: 95%;
	  }
	  .perex {
		font-size: 85%;
	  }
	  &:hover:not(.touching), &.touched {
		z-index: 2;
		left: -18px;
		top: -54px;
		padding: 18px;
		width: calc(100% + 36px);
		box-shadow: 0 0 9px #333;
		h3 {
		  white-space: normal;
		  padding: 11px 0 4px;
		  ~ * {
			display: block;
		  }
		}
	  }
	  &.touched::after {
		content: "ZOBRAZIT DETAIL";
		display: block;
		text-align: right;
		position: relative;
		top: 7px;
	  }
	}
	@media screen and (max-width: 324px) {
	  width: 100%;
	}
	&.lecture-search-results-hidden {
	  display: none;
	}
  }
  .layout-content &, .col-2 & {
	width: auto;
	@media screen and (min-width: 1259px) {
	  .lecture-search-results-item {
		width: 279px;
	  }
	}
	@media screen and (min-width: 823px) and (max-width: 958px) {
	  .lecture-search-results-item {
		width: 270px;
	  }
	}
	@media screen and (min-width: 571px) and (max-width: 642px) {
	  .lecture-search-results-item {
		width: 270px;
	  }
	}
  }
}
.movies-related {
	width: 1230px;
	.lecture-search-results-item {
		width: 244px;
		height: 213px;
		.lecture-search-results-item-inner {
			img {
				height: 157px;
			}
		}
	}
	@media screen and (max-width: 1259px) {
		width: 984px;
	}
	@media screen and (max-width: 1013px) {
		width: 738px;
	}
	@media screen and (max-width: 767px) {
		width: 492px;
	}
	@media screen and (max-width: 521px) {
		width: 302px;
		.lecture-search-results-item {
			width: 300px;
			height: 247px;
			.lecture-search-results-item-inner {
				img {
					height: 191px;
				}
			}
		}
	}
}

/* Lecture detail */
.lecture-detail {
	.layout-sidebar {
		width: 224px;
		.side-nav {
			max-width: 224px;
			padding: 9px 0;
			ul li a {
				font-size: 15px;
				padding: 6px 30px 6px 12px;
				.icon {
					right: 12px;
					top: 11px;
				}
			}
		}
	}
	.layout-content {
		width: calc(100% - 287px);
	}
	@media (max-width: 1079px) {
		.layout-sidebar {
			.side-nav {
				max-width: 170px;
			}
			width: 170px;
		}
		.layout-content {
			width: calc(100% - 215px);
		}
	}
	@media (max-width: 691px) {
		.layout-sidebar, .layout-content {
			width: auto;
		}
	}
	.lecture-detail-content-block {
		padding-top: 43px;
	}
}

#lecture-popup {
	background: #f9f9f9;
	.modal-content {
		min-width: 420px;
		min-height: 120px;
		max-width: calc(min(80vw, 800px));
	}
	.modal-actions {
		text-align: right;
		padding: 10px 20px;
		.btn {
			border-color: #054a7e;
			background: #054a7e;
			color: white;
			&:hover {
				border-color: #e2001a;
				background: #e2001a;
			}
		}
	}
}

.anchor {
	height: 0;
	position: relative;
	top: -30px;
}

/************************************************************************************
QTIP
*************************************************************************************/

.qtip-default { font: normal 12px/20px; line-height: 150%; padding: 5px; background-color: rgba(0,0,0,0.9); color: rgba(0,0,0,0.9); border: none; @include border-radius(5px); }
.qtip-content { font-size: 12px; color: #fff; }

/************************************************************************************
FANCYBOX
*************************************************************************************/

body .fancybox-wrap { padding-bottom: 18px; }

body .fancybox-inner.alt { overflow: visible !important; height: auto !important; }

body .fancybox-close { width: 60px; height: 60px; background: url('../img/fancybox/fancybox.png') 0 0 no-repeat; right: 0; top: 0; }
body .fancybox-close:hover { background-position: 0 -60px; }

body .fancybox-prev { position: static; }
body .fancybox-prev span { width: 40px; height: 60px; display: block; background: url('../img/fancybox/fancybox.png') -60px 0 no-repeat; position: absolute; top: 50%; text-indent: -80000px; left: -50px; visibility: visible; }
body .fancybox-prev:hover span { background-position: -60px -60px;  }

body .fancybox-next { position: static; }
body .fancybox-next span { width: 40px; height: 60px; display: block; background: url('../img/fancybox/fancybox.png') -100px 0 no-repeat; position: absolute; top: 50%; text-indent: -80000px; right: -50px; visibility: visible; }
body .fancybox-next:hover span { background-position: -100px -60px; }

body .fancybox-yt .fancybox-inner { overflow: hidden !important; }

.external-content-fullscreen-button {
  width: 60px;
  height: 60px;
  background: url("../img/fancybox/fancybox.png") -140px 0 no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  &:hover {
	background-position: -140px -60px;
  }
}

#lecture-external-content-confirm-close {
  display: none;
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
#lecture-external-content-confirm-close-inner {
  position: absolute;
  width: 270px;
  left: calc(50% - 135px);
  top: 30%;
  background: white;
  padding: 18px;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 9px 22px rgba(0, 0, 0, 0.5);
  input {
	display: inline-block;
	border: 2px solid #e2001a;
	background: #e2001a;
	color: white;
	padding: 7px 14px;
	font-size: 13px;
	font-weight: bold;
	margin: 0 4px;
	&:hover {
	  background: white;
	  color: #e2001a;
	}
  }
}

/************************************************************************************
MODAL
*************************************************************************************/

.modal-box { display: none; background: #eee; }
.modal-content { padding: 36px 27px; }

/* Modal contact */

.modal-contact { width: 100%; max-width: 540px; }

/* Modal register */

.modal-register { width: 100%; max-width: 540px; }
.modal-register h3 { margin-bottom: 27px; }

#popupform {
  max-width: 388px;
}

/************************************************************************************
UNIFORM
*************************************************************************************/

/* Select */

div.selector { font-size: 15px; background: #fff; color: #054a7e; border: 2px solid #afc5d6; line-height: 40px; height: 41px; width: 100% !important; padding: 0; position: relative; overflow: hidden; cursor: pointer; text-align: left; }
div.selector span { text-overflow: ellipsis; display: block; overflow: hidden; white-space: nowrap; background: #fff url('../img/select.png') right 18px no-repeat; border: none; padding: 0; text-indent: 14px; height: 38px; line-height: 38px; cursor: pointer; display: block; width: 100% !important; }
div.selector select { text-align: left; opacity: 0; filter: alpha(opacity=0); -moz-opacity: 0; border: none; background: none; position: absolute; height: 38px; line-height: 38px; top: 0; left: 0; width: 100%; cursor: pointer; }
div.selector select option { padding: 2px 0; }

div.selector.active span { background-position: right 20px; }
div.selector.focus span { background-position: right 20px; }
div.selector.hover span { background-position: right -30px; }
div.selector.hover.active span { background-position: right -30px; }
div.selector.focus.active span { background-position: right -30px; }

/* Checkbox */

div.checker { position: relative; left: -1px; top: -1px; }
div.checker, div.checker span, div.checker input { width: 20px; height: 20px; }
div.checker span { display: -moz-inline-box; display: inline-block; *display: inline; zoom: 1; text-align: center; background: url('../img/uf.png') -52px -11px no-repeat; }
div.checker span.checked { background-position: -52px -51px; }
div.checker input { margin: 0; cursor: pointer; opacity: 0; filter: alpha(opacity=0); -moz-opacity: 0; border: none; background: none; display: -moz-inline-box; display: inline-block; *display: inline; zoom: 1; vertical-align: top; }

div.checker.active span { background-position: -52px -51px; }
div.checker.active span.checked { background-position: -52px -11px; }
div.checker.hover span, div.checker.focus span { background-position: -52px -11px; }
div.checker.hover span.checked, div.checker.focus span.checked { background-position: -52px -51px; }
div.checker.hover.active span, div.checker.focus.active span { background-position: -52px -11px; }
div.checker.hover.active span.checked, div.checker.focus.active span.checked { background-position: -52px -51px; }

/* Radio */

div.radio { position: relative; }
div.radio, div.radio span, div.radio input { width: 24px; height: 26px; cursor: pointer; }
div.radio span { display: -moz-inline-box; display: inline-block; *display: inline; zoom: 1; text-align: center; background: url('../img/uf.png') -8px -8px no-repeat; }
div.radio span.checked { background-position: -8px -48px; }
div.radio input { margin: 0; opacity: 0; filter: alpha(opacity=0); -moz-opacity: 0; margin: 0; border: none; background: none; display: -moz-inline-box; display: inline-block; *display: inline; zoom: 1; text-align: center; }

div.radio.active span { background-position: -8px -48px; }
div.radio.active span.checked { background-position: -8px -8px; }
div.radio.hover span, div.radio.focus span { background-position: -8px -48px; }
div.radio.hover span.checked, div.radio.focus span.checked { background-position: -8px -48px; }
div.radio.hover.active span, div.radio.focus.active span { background-position: -8px -48px; }
div.radio.hover.active span.checked, div.radio.focus.active span.checked { background-position: -8px -48px; }

/* Uploader */

div.uploader { background-position: 0 -297px; height: 25px; width: 171px; cursor: pointer; position: relative; overflow: hidden; }
div.uploader span.action { background-position: right -409px; height: 25px; line-height: 25px; width: 77px; text-align: center; float: left; display: inline; overflow: hidden; cursor: pointer; }
div.uploader span.filename { text-overflow: ellipsis; display: block; overflow: hidden; white-space: nowrap; float: left; cursor: default; height: 22px; margin: 2px 0 2px 2px; line-height: 22px; width: 74px; padding: 0 9px; }
div.uploader input { opacity: 0; filter: alpha(opacity=0); -moz-opacity: 0; border: none; background: none; position: absolute; top: 0; right: 0; float: right; cursor: default; width: 100%; height: 100%; }
div.uploader.active span.action { background-position: right -465px; }
div.uploader.hover, div.uploader.focus { background-position: 0 -353px; }
div.uploader.hover span.action, div.uploader.focus span.action { background-position: right -437px; }
div.uploader.hover.active span.action, div.uploader.focus.active span.action { background-position: right -493px; }
div.uploader.disabled, div.uploader.disabled.active { background-position: 0 -325px; }
div.uploader.disabled span.action, div.uploader.disabled.active span.action { background-position: right -381px; }

/************************************************************************************
SUDO SLIDERS
*************************************************************************************/

.slider ul, .slider li.panel { margin: 0; padding: 0; list-style: none; position: relative; display: block; }
.slider, .slider li.panel { overflow: hidden; background: none; }
.slider li.panel { display: inline-block; vertical-align: top; width: 100%; }

.slider-nav { margin-top: 18px; display: block; text-align: center; width: 100%; }
.slider-nav ol { margin: 0 0 0 -9px; z-index: 100;  }
.slider-nav li { margin: 0 0 0 9px; padding: 0; background: none; display: inline-block; font-size: 0; }
.slider-nav li:before { content: none; }
.slider-nav li a { width: 7px; height: 7px; display: block; background: #2f4e75; text-indent: -80000px; @include border-radius(50%); }
.slider-nav li.current a { background: #b8bdc5; }

.slider-nav .prev { position: absolute; left: 18px; top: 50%; display: inline-block; width: 2em; height: 2em; border-left: 0.2em solid #87cff6; border-bottom: 0.2em solid #87cff6; transform: rotate(45deg); margin-right: 0.5em; display: block; z-index: 100; }
.slider-nav .prev:hover { border-color: #e2001a; }
.slider-nav .next { position: absolute; right: 18px; top: 50%; display: inline-block; width: 2em; height: 2em; border-right: 0.2em solid #87cff6; border-top: 0.2em solid #87cff6; transform: rotate(45deg); margin-right: 0.5em; display: block; z-index: 100;  }
.slider-nav .next:hover { border-color: #e2001a; }

/* Service slider */

.service-slider { margin-bottom: 36px; margin-left: -9px; margin-right: -9px; }
.service-slider li.panel .item { padding: 0 9px; }
.service-slider li.panel .item-inner { padding: 18px; background: #fff; }

/* Teaser slider */

.teaser-slider { margin: 0; position: relative; }
.teaser-slider li.panel { background-size: cover !important; }
.teaser-slider li.panel:after { content: ''; position: absolute; left: 0; top: 0; z-index: 10; width: 100%; height: 100%; background: rgba(5,74,126,0.83); }
.teaser-slider li.panel .teaser-content { position: relative; z-index: 20; }
.teaser-slider li.panel:not(:first-child) { display: none; }
.teaser-slider .slider-nav { margin: 0; }
.teaser-slider .slider-nav ol { position: absolute; right: 27px; top: 27px; margin-left: -5px; }
.teaser-slider .slider-nav ol li { padding-left: 5px; }
.teaser-slider .slider-nav ol li a { width: 18px; height: 18px; border: 2px solid #8ed8ff; background: none; @include border-radius(0); }
.teaser-slider .slider-nav ol li a:hover { border-color: #e2001a; }
.teaser-slider .slider-nav ol li.current a { background: #fff; border-color: #fff; }

/* Partner slider */

.partner-slider { position: relative; z-index: 30; }

/* Video subtitles */

.jw-text-track-cue {
  .white { color: rgba(255,255,255,1); }
  .lime { color: rgba(0,255,0,1); }
  .cyan { color: rgba(0,255,255,1); }
  .red { color: rgba(255,0,0,1); }
  .yellow { color: rgba(255,255,0,1); }
  .magenta { color: rgba(255,0,255,1); }
  .blue { color: rgba(0,0,255,1); }
  .black { color: rgba(0,0,0,1); }
  .bg_white { background-color: rgba(255,255,255,1); }
  .bg_lime { background-color: rgba(0,255,0,1); }
  .bg_cyan { background-color: rgba(0,255,255,1); }
  .bg_red { background-color: rgba(255,0,0,1); }
  .bg_yellow { background-color: rgba(255,255,0,1); }
  .bg_magenta { background-color: rgba(255,0,255,1); }
  .bg_blue { background-color: rgba(0,0,255,1); }
  .bg_black { background-color: rgba(0,0,0,1); }
}
::cue(.white) { color: rgba(255,255,255,1); }
::cue(.lime) { color: rgba(0,255,0,1); }
::cue(.cyan) { color: rgba(0,255,255,1); }
::cue(.red) { color: rgba(255,0,0,1); }
::cue(.yellow) { color: rgba(255,255,0,1); }
::cue(.magenta) { color: rgba(255,0,255,1); }
::cue(.blue) { color: rgba(0,0,255,1); }
::cue(.black) { color: rgba(0,0,0,1); }
::cue(.bg_white) { background-color: rgba(255,255,255,1); }
::cue(.bg_lime) { background-color: rgba(0,255,0,1); }
::cue(.bg_cyan) { background-color: rgba(0,255,255,1); }
::cue(.bg_red) { background-color: rgba(255,0,0,1); }
::cue(.bg_yellow) { background-color: rgba(255,255,0,1); }
::cue(.bg_magenta) { background-color: rgba(255,0,255,1); }
::cue(.bg_blue) { background-color: rgba(0,0,255,1); }
::cue(.bg_black) { background-color: rgba(0,0,0,1); }

/************************************************************************************
PIMCORE
*************************************************************************************/

img.x-form-trigger { display: inline !important; }
div.pimcore_tag_multihref { background-color: #fff; }
table.x-btn { line-height: normal !important; margin: 0 !important; padding: 0 3px 0 3px !important; }
table.x-btn td { line-height: normal !important; margin: 0 !important; padding: 0 !important; }
table.x-btn tr { border: none !important; }
.x-menu-list-item { line-height: normal !important; }
.ib script { display: none !important; }

.col2-l-set .col-2 .pimcore_block_clear, .layout-content .pimcore_block_clear { clear: none !important; }
.col2-l-set .col-2 .pimcore_block_entry, .layout-content .pimcore_block_entry { clear: none !important; }

.pimcore_block_buttons { font-family: 'Arial',sans-serif; letter-spacing: -0.31em; *letter-spacing: normal; *word-spacing: -0.43em; }
.pimcore_block_plus, .pimcore_block_minus, .pimcore_block_up, .pimcore_block_down, .pimcore_block_amount, .pimcore_block_type, .pimcore_block_options { float: none !important; display: inline-block; *display: inline; zoom: 1; vertical-align: top; letter-spacing: normal; word-spacing: normal; }

/*========================= Editor =========================*/

.cke_panel_container { background: #fff !important; }

.cke_ltr { background: #fff !important; }
.cke_ltr ul li { background: none; padding: 0; }
.cke_panel_listItem p, .cke_panel_listItem h1, .cke_panel_listItem h2, .cke_panel_listItem h3, .cke_panel_listItem h4, .cke_panel_listItem h5, .cke_panel_listItem h6, .cke_panel_listItem pre { font-size: inherit; font-style: normal; font-family: sans-serif; line-height: 30px; color: #000 !important; margin: 0; padding: 0; text-shadow: none; }
.cke_panel_listItem a { color: #000 !important; }
.cke_panel_listItem a:hover { color: #000 !important; }

.pimcore_editable { line-height: normal !important; }
.pimcore_area_entry { margin: 0 !important; padding: 0 !important; }

/************************************************************************************
AJAX
*************************************************************************************/

.loader-overlay { display: none; z-index: 9990; width: 100%; height: 100%; position: fixed; left: 0; top: 0; background-image: url('../img/fancybox/fancybox_overlay.png');  }
.loader-box { position: absolute; width: 44px; height: 44px; margin-top: -22px; margin-left: -22px; opacity: 0.8; left: 50%; top: 50%; background-image: url('../img/fancybox/fancybox_sprite.png'); background-position: 0 -108px; background-repeat: no-repeat; }
.loader {width: 100%; height: 100%; position: absolute; left: 0; top: 0; background-image: url('../img/fancybox/fancybox_loading.gif'); background-position: center center; background-repeat: no-repeat; }

/************************************************************************************
MEDIA QUERIES
*************************************************************************************/

/*========================= Viewport width 1440px and lower =========================*/

@media screen and (max-width: 1296px) {
    .header { height: 122px; }
    .header .logo { max-width: 198px; top: 60px; }

    .main-nav { top: 72px; }
    .main-nav ul li { font-size: 17px; }

    .teaser-img h1 { font-size: 27px; line-height: 1.3em; }
    .teaser-img .head { padding-left: 81px; padding-right: 36px; }

	.news .item { width: 25%; }
	.layout-content .news .item { width: 50%; }

    .projects .item .text h2 { font-size: 18px; line-height: 1.3em; }
    .projects .item .text { padding: 14px 16px 9px 16px; min-height: 112px; }

    .img-box .text { font-size: 15px; line-height: 1.625em; }
    .img-box .col2-set { margin-left: -18px; }
    .img-box .col2-set .col { padding-left: 18px; }

    .partners .imgs { max-width: calc(100% - 360px); }

    .col2-l-set .col-2 .filter .head, .layout-content .filter .head { display: none; }
    .col2-l-set .col-2 .filter .fields, .layout-content .filter .fields { padding-left: 0; }
}

/*========================= Viewport width 1199px and lower =========================*/

@media screen and (max-width: 1079px) {
    .main-nav ul li { font-size: 15px; padding-left: 14px; }

    .service-nav ul li a { font-size: 15px; }

    .side-nav { max-width: 270px; }
    .side-nav ul li a { font-size: 15px; }

    .teaser { height: auto; }
    .teaser-img { padding-right: 0; height: 360px; }
    .teaser-img h1 { margin-bottom: 27px; }
    .teaser-img .btn-video { margin-left: 0; }
    .teaser-img .btn-video .img { display: none; }
    .teaser-img .btn-wrap .title { left: 0; padding-top: 0; padding-left: 0; margin-top: -9px; }
    .teaser-content { height: 360px; }
    .teaser-content .btn { margin-bottom: 18px; }

    .teaser-nav { position: static; width: 100%; height: auto;}
    .teaser-nav .item:first-child { border-left: none; }
    .teaser-nav .item { width: 33.333333%; border-left: 1px solid #0c76b4; border-bottom: none; height: 135px; }
    .teaser-nav .item h3 { margin-right: 108px; }
    .teaser-nav .item .img { width: 90px; }

    .partners .item-list .item { width: 25%; }

    /*.news .item:last-child { display: none; }*/

    .news .item .text { border-left: 2px solid #d5dce4; }
	.news .item .head { padding: 10px; }
	.news .item h3 { font-size: 14px; line-height: 1.5em; }
    .news .item:nth-child(4n+1) .text { border-left: 2px solid #d5dce4; }
    .news .item:nth-child(3n+1) .text { border-left: none; }

	.col2-l-set .col-2, .layout-content {
		.filter .head { display: none; }
		.filter .cell-set.set-4 .cell { width: 50%; }
	}

    .movies .head { float: none; width: 100%; margin-bottom: 18px; position: relative; }
    .movies .head h2 { max-width: 100%; min-height: 0; }
    .movies .item-list { padding-left: 0; }
    .movies .item { float: none; }
    .movies .item-list.full .item { width: 25%; }
    .movies .item-list.full .item:last-child { display: none; }

    .img-box .col2-set:after { content: none; }
    .img-box .col2-set { margin-left: 0; }
    .img-box .col2-set .col { width: 100%; padding-right: 0; padding-left: 0; margin-bottom: 36px; }
    .img-box .col2-set .col:last-child { margin-bottom: 0; }
    .img-box .text p { margin-bottom: 27px; }

    .activities .item { width: 25%; }
    .activities .item .text { border-left: 2px solid #c0cad6; }
    .activities .item:nth-child(5n+1) .text { border-left: 2px solid #c0cad6; }
    .activities .item:nth-child(4n+1) .text { border-left: none; }

    .post-list .item .img { width: 198px; }
    .post-list .item .text { padding-left: 230px; }

    .col2-l-set { margin: 0; }
    .col2-l-set .col-1 { width: 270px; }
    .col2-l-set .col-2 { padding-left: 315px; }

    .movie-list .item .img { width: 126px; }
    .movie-list .item .item-text { padding-left: 153px; }

    .persons .item .img { float: none; margin-bottom: 18px; }
    .persons .item .text { padding-left: 0; }

    .photogallery .item { width: 50%; }

    .photogallery-detail .item { width: 33.33333%; }

    .user-type .item { width: 100%; margin-bottom: 27px; text-align: left; }
    .user-type .item h3 { min-height: 0; }

    form.form-filter .cell-set.set-6 .cell { width: 33.33333%; }

    .posts-inline .item-hidden .item-inner .img { max-width: 288px; }
    .posts-inline .item-hidden .item-inner .text { padding-left: 315px; }

  	.posts-inline-wide {
	  .item:not(.item-hidden) .item-inner {
		.img img {
		  max-width: 216px;
		}
		div.text {
		  padding-left: 234px;
		}
	  }
	}

  .projects .item { width: calc(33% - 22px); }
}

/*========================= Viewport width 991px and lower =========================*/

@media screen and (max-width: 892px) {
    .main-nav { display: none; }
    .service-nav { padding-right: 45px; padding-top: 7px; }
    .service-nav ul li { display: none; }
    .service-nav ul li.li-cart { display: inline-block; }
    .service-nav ul li.li-cart .text { display: none; }

    .side-nav.fixed { max-width: 225px; }

    .nav-btn { display: inline-block; }

    .header { height: 90px; }
    .header .logo { max-width: 162px; left: 14px; top: 34px; }

    .news { max-width: 864px; margin: 0 auto; }
	.news .item { width: 50%; }
	.layout-content .news .item { width: 100%; }
    .news .item .text { border-left: 2px solid #d5dce4; }
    .news .item:nth-child(3n+1) .text { border-left: 2px solid #d5dce4; }
    .news .item:nth-child(2n+1) .text { border-left: none; }

    .teaser-img { height: auto; }
    .teaser-img h1 { font-size: 25px; line-height: 1.5em; }
    .teaser-img .head { top: 0; @include translate(0,0); }
    .teaser-content { padding: 81px 0; height: auto; }

    .teaser-nav .item { padding-left: 14px; padding-right: 14px; height: 108px; }
    .teaser-nav .item h3 { font-size: 15px; line-height: 1.5em; margin-left: 90px; margin-right: 0; }
    .teaser-nav .item .img { width: 63px; right: auto; left: 14px; }

    .movies .head { width: 270px; float: left; }
    .movies .head a { position: relative; }
    .movies .item-list .item { width: 50%; }
    .movies .item-list.full .item { width: 33.33333%; }
    .movies .item-list.full .item:last-child { display: inline-block; }

    .btn { font-size: 15px; padding: 16px 18px 14px 18px; }

    .footer .copy { margin-bottom: 45px; }

    .activities .item { width: 33.33333%; }
    .activities .item .text { border-left: 2px solid #c0cad6; }
    .activities .item:nth-child(4n+1) .text { border-left: 2px solid #c0cad6; }
    .activities .item:nth-child(3n+1) .text { border-left: none; }

    .movie-list .item .img { float: none; margin-bottom: 18px; }
    .movie-list .item .item-text { padding-left: 0; }

    .col2-l-set { margin: 0; }
    .col2-l-set .col-1 { width: 225px; }
    .col2-l-set .col-2 { padding-left: 252px; }

    .photogallery-detail .item { width: 50%; }

    .partners.archive .item { width: 33.33333%; }

    .partners { text-align: center; }
    .partners .head { float: none; margin: 0 auto; text-align: center; }
    .partners .item-list .item { width: auto; }
    .partners .imgs { max-width: 675px; float: none; margin: 0 auto; padding-top: 27px; }

    .learning-materials .item-list { margin-left: 0; }
    .learning-materials .item { width: 100%; padding-left: 0; }

    .posts-inline .item { width: 50%; }
    .posts-inline .item-hidden .item-inner .img { padding-right: 0; float: none; }
    .posts-inline .item-hidden .item-inner .text { padding-left: 0; }

    form.form-filter .cell-set.set-6 .cell { width: 50%; }

	.posts-inline-wide {
	  .item:not(.item-hidden) .item-inner {
		.img img {
		  max-width: 162px;
		}
		div.text {
		  padding-left: 180px;
		}
	  }
	}
}

/*========================= Viewport width 767px and lower =========================*/

@media screen and (max-width: 691px) {
    .section-head-l .right { position: static; }

    .partners.archive .item { width: 33.33333%; }

    .footer { text-align: center; }
    .footer .copy { margin: 0 auto 45px auto; max-width: 288px; padding-left: 99px; position: relative; text-align: left; }

    .img-box .img { width: 100%; position: relative; min-height: 234px; }
    .img-box .text { display: none; }

    .teaser-nav .item { width: 100%; border-bottom: 1px solid #0c76b4; border-left: none; }
    .teaser-nav .item h3 { margin-right: 90px; }
    .teaser-nav .item:last-child { border-bottom: none; }

    .movies .head { width: 180px; }
  	.movies .head h2 { font-size: 19px; }
    .movies .item-list.full .item, .movies .item-list.medium .item { width: 50%; }

    .filter { text-align: center; }
    .filter .head { float: none; display: inline-block; vertical-align: top; }
    .filter .fields { padding-right: 0; padding-left: 0; margin-bottom: 18px; text-align: left; }

    .side-nav.fixer { display: none !important; }
    .side-nav { margin: 0 auto 36px auto; padding: 0; width: 100%; max-width: 100% }
	.side-nav > ul{ display: none;}
	.side-nav .trigger{ display: block; font-size: 15px; line-height: 1.5em; padding: 9px 40px 9px 9px; cursor:pointer; position: relative;}
	.side-nav .trigger::after{
		position: absolute;
		height: 5px;
		border-bottom: 12px double #e2001a;
		border-top: 4px solid #e2001a;
		content: ' ';
		width: 21px;
		padding-top: 5px;
		right: 9px;
		top: 50%;
		margin-top: -9px;
	}


	.side-nav.open>ul{ display: block; position: absolute; overflow: auto;border: 2px solid #ccd3dc; background: white; left: -2px; right: -2px; z-index: 100; border-top: 1px solid #ccd3dc}
	.side-nav.open .trigger{background: #F1F1F1};
	.side-nav ul li a{color: black !important; text-decoration: none !important;}

    .col2-l-set .col-1 { width: 100%; max-width: 100%; float: none; }
    .col2-l-set .col-2 { padding-left: 0; }

    .cart-nav ul li { width: 100%; margin: 0; }
    .cart-nav ul li a { border: 2px solid #054a7e; border-top: none; }
    .cart-nav ul li:first-child a { border-top: 2px solid #054a7e; }

    .table-cart { margin: 0; }
    .table-cart th { display: none; }
    .table-cart tr { display: block; width: 100%; position: relative; }
    .table-cart td { display: block; width: 100%; padding-left: 0; padding-right: 0; }
    .table-cart td.item { padding-right: 63px; padding-bottom: 0; }
    .table-cart td.val { border-top: none; display: inline-block; vertical-align: top; padding-top: 0; width: auto; }
    .table-cart td.price { border-top: none; display: inline-block; vertical-align: top; width: auto; float: right; padding-top: 7px; }
    .table-cart td.remove { display: block; position: absolute; right: 0; top: 18px; width: auto; border: none; padding: 0; }
    .table-cart td .img { display: block; margin-bottom: 18px; }
    .table-cart tfoot { border-top: 2px solid #d5dce4; }
    .table-cart tfoot td { border: none; display: inline-block; vertical-align: top; width: auto; }

    .cart-actions { text-align: left; }
    .cart-actions .back { float: none; margin-bottom: 9px; display: block; }

    .pager .prev { display: none; }
    .pager .next { display: none; }

    .footer .social { text-align: center; padding-top: 18px; }

    .posts-inline .item-hidden .text h2 { font-size: 22px; line-height: 1.2em; }
    .posts-inline .item-hidden .item-inner { padding: 14px; }

    .learning-materials .item-list { margin-left: -9px; }
    .learning-materials .item { width: 50%; padding-left: 9px; }

    form.form-filter .btn { position: static; }

	#lecturefilterform .cell-set { border-left: none; }
  	#lecturefilterform .cell { width: 100% !important; max-width: 100% !important; border-left: 2px solid #afc5d6; }
  	#lecturefilterform .cell:nth-child(7), #lecturefilterform .cell:nth-child(8), #lecturefilterform .cell:nth-child(9), #lecturefilterform .cell:nth-child(10) { padding-left: 11px; }

    .select2-expandable-mouseover .select2-results__options { display: none !important; }
	.select2-expandable-opened-by-mouse .select2-expandable-optgroup .select2-results__options { position: static; border: 0; width: auto; height: auto; }
	.select2-expandable-opened-by-mouse .select2-expandable-optgroup .select2-lecture-filter-item { padding-left: 7px; }
	.select2-expandable-opened-by-mouse .select2-expandable-optgroup .select2-lecture-filter-item::before { display: block; }

	.posts-inline-wide {
	  .item:not(.item-hidden) .item-inner {
		.img img {
		  max-width: 216px;
		}
		div.text {
		  padding-left: 234px;
		}
	  }
	}

  .projects .item { width: calc(50% - 22px); }

	.layout-content .news .item { width: 50%; }
}

/*========================= Viewport width 640px and lower =========================*/

@media screen and (max-width: 576px) {
    h1, .h1 { font-size: 23px; line-height: 1.3em; }
    h2, .h2 { font-size: 21px; line-height: 1.3em; }

    .block { padding: 36px 0; }

    .teaser-content { padding: 36px 0; }

    .teaser-img { text-align: center; }
    .teaser-img .head { padding-left: 14px; padding-right: 14px; }

	.layout-content .news .item { width: 100%; }
	.news .item h3, .news .item .date { font-size: 12px; }
    .news .item .text { border-left: none; }
    .news .item:nth-child(2n+1) .text { border-left: none; }

    .img-box .text { padding-left: 14px; padding-right: 14px; }

    .teaser-slider .slider-nav .prev { display: none; }
    .teaser-slider .slider-nav .next { display: none; }
    .teaser-slider .slider-nav ol { bottom: 5px; top: auto; left: 50%; right: auto; @include translate(-50%,0); }

    .movies .head { width: 100%; float: none; }
    .movies .head h2 { font-size: 18px; }
    .movies .item-list { padding-left: 0; }
    .movies .item-list .item { width: 50%; }

    .box-lecture .box-content .img { margin: 0 auto 18px auto; float: none; }
    .box-lecture .box-content .text { padding-left: 0; }

    .activities .item { width: 50%; }
    .activities .item .text { border-left: 2px solid #c0cad6; }
    .activities .item:nth-child(3n+1) .text { border-left: 2px solid #c0cad6; }
    .activities .item:nth-child(2n+1) .text { border-left: none; }

    .movie-list .item .item-text table tr { display: block; }
    .movie-list .item .item-text table td { display: block; }

    .modal-dialog .btn { width: 100%; margin-bottom: 9px; }

    .partners.archive .item { width: 50%; }

    .posts-inline .item { width: 100%; margin-bottom: 18px;}
    .posts-inline .item:not(.item-hidden) .img { width: 72px; height: auto; float: left; }
    .posts-inline .item:not(.item-hidden) div.text { padding-left: 90px; }

    .posts-inline .item .more.active:before { display: none; }
    .posts-inline .item .more.active:after { display: none; }

    form.form-cart .set-3 .cell { width: 50%; }

	.posts-inline-wide {
	  .item:not(.item-hidden) .item-inner {
		.img img {
		  max-width: 162px;
		}
		div.text {
		  padding-left: 180px;
		}
	  }
	}
}

/*========================= Viewport width 540px and lower =========================*/

@media screen and (max-width: 486px) {
    .post-list .item .img { width: 100%; margin: 0 auto 18px auto; }
    .post-list .item .text { padding-left: 0; }

    .table-wrap { overflow-x: scroll; max-width: 100%; }

    .text-big { font-size: 14px; line-height: 1.625em; }

    .box-movie .text { padding-left: 22px; padding-right: 22px; }

    .learning-materials .item-list { margin-left: 0; }
    .learning-materials .item { width: 100%; padding-left: 0; }

    .topic-related .img { float: none; margin: 0 0 18px 0; }
    .topic-related .text { padding-left: 0; }

    .movie-tag { text-align: center; }
    .movie-tag .item { font-size: 15px; position: static; display: inline-block; margin: 0 auto; @include translate(0,0); }
    .movie-tag .item:before { content: none; }
    .movie-tag .item:after { content: none; }

    .table-cart td .img { float: none; }
    .table-cart td .text { padding-left: 0; }

    .persons .item { width: 100%; }

    form.std .set-3 .cell { width: 100%; }

    .teaser-img .btn-wrap .title { position: static; display: block; margin-bottom: 15px; }

    .projects .item { width: calc(100% - 22px); }

	h3 {
	  img {
		&.alignleft, &.alignright {
		  float: none;
		  display: block;
		  margin-left: 0;
		  margin-right: 0;
		}
	  }
	}
}

/*========================= Viewport width 420px and lower =========================*/

@media screen and (max-width: 378px) {
    .movies .head { width: 100%; float: none; }
    .movies .head h2 { margin-bottom: 9px; }
    .movies .head a { position: relative; top: 0; }
    .movies .item-list { padding-left: 0; }
    .movies .item-list .item { width: 100%; }
    .movies .item-list.full .item, .movies .item-list.medium .item { width: 100%; }

    .activities { max-width: 288px; margin-left: auto; margin-right: auto; }
    .activities .item { width: 100%; }
    .activities .item .text { border-left: none; }
    .activities .item:nth-child(5n+1) .text { border-left: none; }
    .activities .item:nth-child(3n+1) .text { border-left: none; }

    .persons .item .img { float: none; display: block; margin: 0 0 18px 0; }
    .persons .item .text { padding-left: 0; }

    .side-info { text-align: center; }
    .side-info ul li { padding-left: 0; }
    .side-info ul li .icon { display: block; margin: 0 auto 9px auto; position: static; }

    .photogallery .item { width: 100%; }
    .photogallery .item .text { border: none; }

    .photogallery-detail .item { width: 100%; }

    .modal-content { padding-left: 14px; padding-right: 14px; }

    img {
	  &.alignleft, &.alignright {
		float: none !important;
		display: block;
		margin-left: 0;
		margin-right: 0;
	  }
	}

    form.form-cart .set-3 .cell { width: 100%; }

    form.std .set-2 .cell { width: 100%; }

	.posts-inline-wide {
	  .item:not(.item-hidden) .item-inner {
		.img {
		  float: none;
		  width: 100%;
		  img {
			max-width: 100%;
		  }
		}
		div.text {
		  padding-left: 0;
		}
	  }
	}

  table.responsive-table {
	border-width: 0;
	&, tbody, tr, td {
	  display: block;
	}
	thead, th {
	  display: none;
	}
	td {
	  padding: 9px 0;
	  &[data-col-label]::before {
		content: attr(data-col-label);
		display: block;
		font-weight: bold;
		font-size: 75%;
	  }
	  &:first-child {
		border-top: 2px solid #809db8;
	  }
	  &:last-child {
		border-bottom-width: 0;
	  }
	}
	tr:last-child td:last-child {
	  border-bottom: 2px solid #809db8;
	}
  }

	.news .item {
		width: 100%;
	}
}
@media screen and (min-width: 379px) {
  .school-input .btn {
	margin-top: 28px;
  }
}

/************************************************************************************
TRANSITIONS & ANIMATIONS
*************************************************************************************/

a {
    transition: color 0.1s;
    -moz-transition: color 0.1s;
    -webkit-transition: color 0.1s;
    -o-transition: color 0.1s;
    -ms-transition: color 0.1s;
}

/************************************************************************************
CLEARFIX
*************************************************************************************/

/* http://www.yuiblog.com/blog/2010/09/27/clearfix-reloaded-overflowhidden-demystified */

.clearfix:before,
.clearfix:after {
  content: '\0020';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

